import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { JsonLdModule } from 'ngx-seo';

@NgModule({
  declarations: [
    AppComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ffb' }),
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    RouterModule,
    BrowserAnimationsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    JsonLdModule,           // <--
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true,},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
