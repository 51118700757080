<footer class="footer" lazyLoad="assets/images/footer-bg.jpg">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="footerCol">
                    <p class="footerColHeader">Contact Information</p>
                    <div class="footerContactInfo">
                        <div class="footerContactInfoItem">
                            <img src="assets/images/icons/placeholder.svg" />
                            <div>
                                Bengal Fisheries and Seafood Pvt Ltd, <br>
                                Sector - 3, Dwarka, New Delhi - 110078
                            </div>
                        </div>
                        <div class="footerContactInfoItem">
                            <img src="assets/images/icons/telephone.svg" />
                            <div *ngIf="settingObj">
                              <a class="white" href="tel:{{settingObj.phone}}">
                                {{settingObj.phone}} (9:00am to 7:00pm) (Call for Order)
                              </a>
                            </div>
                        </div>
                        <div class="footerContactInfoItem">
                            <!-- <img src="assets/images/icons/whatsapp.svg" /> -->
                            <img src="assets/images/icons/telephone.svg" />
                            <div *ngIf="settingObj">
                              <a class="white" href="tel:{{settingObj.whatsapp_phone}}">
                                {{settingObj.whatsapp_phone}} (9:00am to 7:00pm) (Query)
                              </a>
                            </div>
                        </div>
                        <div class="footerContactInfoItem">
                            <img lazyload="assets/images/icons/envelope.svg" src="" />
                            <div>
                                info@freshfishbasket.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footerCol">
                    <p class="footerColHeader">Useful Links</p>
                    <div class="footerLinksHolder">
                        <a [routerLink]="['/page/about-us']">About us</a>
                        <!-- <a [routerLink]="['/page/delivery-packaging-weight']">Delivery, Packaging and Weight</a> -->
                        <a [routerLink]="['/contact-us']">Contact us</a>
                        <a [routerLink]="['/page/why-fresh-fish-basket']">Why Fresh Fish Basket</a>
                        <a [routerLink]="['/page/refer-and-earn']">Refer and Earn </a>
                        <a [routerLink]="['/page/offers']">Offers </a>
                        <a href="javascript:;" (click)="ratingsCaller()">Ratings</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footerCol">
                    <p class="footerColHeader">Information</p>
                    <div class="footerLinksHolder">
                        <a [routerLink]="['/page/certificate']">FSSAI Certificate</a>
                        <a [routerLink]="['/page/privacy-policy']">Privacy Policy</a>
                        <a [routerLink]="['/faq']">FAQ'S</a>
                        <a [routerLink]="['/page/terms-of-services']">Terms Of Services</a>
                        <!-- <a [routerLink]="['/page/store-credit']">Store Credit T&C</a> -->
                        <a [routerLink]="['/page/wallet']">Wallet T&C </a>
                        <a [routerLink]="['/page/telecall-order']">Telecall Order T&C </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footerCol">
                    <p class="footerColHeader">Social Media</p>
                    <div class="footerSocial">
                        <!-- <a href="javascript;" (click)="openSocialLink(settingObj,1);"><img src="assets/images/icons/facebook-letter-logo.svg" /></a>
                        <a href="javascript;" (click)="openSocialLink(settingObj,2);"><img src="assets/images/icons/twitter-logo-silhouette.svg" /></a>
                        <a href="javascript;" (click)="openSocialLink(settingObj,3);"><img src="assets/images/icons/instagram-logo.svg" /></a> -->
                        <a href="https://www.facebook.com/FreshFishBasket/" (click)="openSocialLink(settingObj,1);"><img src="assets/images/icons/facebook-letter-logo.svg" /></a>
                        <a href="https://twitter.com/BasketFreshFish" (click)="openSocialLink(settingObj,2);"><img src="assets/images/icons/twitter-logo-silhouette.svg" /></a>
                        <a href="https://www.instagram.com/freshfishbasket/" (click)="openSocialLink(settingObj,3);"><img src="assets/images/icons/instagram-logo.svg" /></a>
                    </div>
                    <br />
                    <p class="footerColHeader">We accept</p>
                    <img lazyLoad="assets/images/payment-options.png" class="paymentOptions" />
                </div>
            </div>
        </div>
        <div class="footerDivider"></div>
      <ng-container *ngIf="content">
        <div class="footerContentItem" [innerHtml]="content.body"></div>
      </ng-container>
    </div>
    <div class="copyrightText">
        &copy; Copyright 2020 by Fresh Fish Basket. All Rights Reserved.
    </div>
</footer>


<div class="ratingsContWrapper">
    <div class="reviewContInner">
      <div class="reviewContent">
        <div class="timeSlotHeader">
          <h2 class="locSelHeaer">Overall Ratings</h2>
          <a href="javascript:;" class="closeTimeSlot closeRevMod"><img src="assets/images/icons/close.svg"/></a>
        </div>
        <div class="reviewContBody rvwGlobalContBody" *ngIf="ratingData">
            <div class="reviewGStarCount">
                <span>{{overallRating}}</span><img src="assets/images/icons/star-straight.svg" alt="">
            </div>
            <div class="reviewTotCount">
                <div class="revTotCountItem">
                    <span class="rtiHead">5</span>
                    <img src="assets/images/icons/star-straight.svg" alt="">
                    <div class="bar"><div class="barFill barFillGreen" [ngStyle]="{'width':  ratingData.starFive + '%'}"></div></div>
                    <span class="rtiHeadCount">{{ratingData.starFiveCount}}</span>
                </div>
                <div class="revTotCountItem">
                    <span class="rtiHead">4</span>
                    <img src="assets/images/icons/star-straight.svg" alt="">
                    <div class="bar"><div class="barFill barFillGreen" [ngStyle]="{'width':  ratingData.starFour + '%'}"></div></div>
                    <span class="rtiHeadCount">{{ratingData.starFourCount}}</span>
                </div>
                <div class="revTotCountItem">
                    <span class="rtiHead">3</span>
                    <img src="assets/images/icons/star-straight.svg" alt="">
                    <div class="bar"><div class="barFill barFillGreen" [ngStyle]="{'width':  ratingData.starThree + '%'}"></div></div>
                    <span class="rtiHeadCount">{{ratingData.starThreeCount}}</span>
                </div>
                <div class="revTotCountItem">
                    <span class="rtiHead">2</span>
                    <img src="assets/images/icons/star-straight.svg" alt="">
                    <div class="bar"><div class="barFill barFillYellow" [ngStyle]="{'width':  ratingData.starTwo + '%'}"></div></div>
                    <span class="rtiHeadCount">{{ratingData.starTwoCount}}</span>
                </div>
                <div class="revTotCountItem">
                    <span class="rtiHead">1</span>
                    <img src="assets/images/icons/star-straight.svg" alt="">
                    <div class="bar"><div class="barFill barFillRed" [ngStyle]="{'width':  ratingData.starOne + '%'}"></div></div>
                    <span class="rtiHeadCount">{{ratingData.starOneCount}}</span>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="ratingsContWrapperOverlay"></div>
