import { Injectable } from '@angular/core';
/*import { CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';*/
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginRegistrationService } from './login-registration.service';
@Injectable({
  providedIn: 'root'
})
export class LoinGuardService implements CanActivate{

  constructor(private router:Router, private auth: LoginRegistrationService) { }
  /*private jwtHelper = new JwtHelperService();
  private loginUser=false;

  canActivate()
  {
    return !this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
  }*/


  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean|UrlTree {
    if (this.auth.isAuthenticated()) {
      return true;
    }
    else {
      this.router.navigate(["/"]);
      return false;
    }

  }
}
