/*import { CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';*/
import { Router } from '@angular/router';
import { LoginRegistrationService } from './login-registration.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./login-registration.service";
var LoinGuardService = /** @class */ (function () {
    function LoinGuardService(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    /*private jwtHelper = new JwtHelperService();
    private loginUser=false;
  
    canActivate()
    {
      return !this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
    }*/
    LoinGuardService.prototype.canActivate = function (route, state) {
        if (this.auth.isAuthenticated()) {
            return true;
        }
        else {
            this.router.navigate(["/"]);
            return false;
        }
    };
    LoinGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoinGuardService_Factory() { return new LoinGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LoginRegistrationService)); }, token: LoinGuardService, providedIn: "root" });
    return LoinGuardService;
}());
export { LoinGuardService };
