import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoryService = /** @class */ (function () {
    function CategoryService(http) {
        this.http = http;
        this.baseURL = environment.baseURL;
    }
    CategoryService.prototype.getCategoryData = function () {
        var _this = this;
        var url = this.baseURL + 'get-categories';
        if (localStorage.getItem("postal_code") != undefined && localStorage.getItem("postal_code") != '') {
            url = url + '?postal_code=' + localStorage.getItem("postal_code");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    CategoryService.prototype.getCategoryBySlug = function (slug) {
        var _this = this;
        var url = this.baseURL + 'get-category/' + slug;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    CategoryService.prototype.getCategoryMenuData = function () {
        var _this = this;
        var url = this.baseURL + 'get-menu-item';
        if (localStorage.getItem("postal_code") != undefined && localStorage.getItem("postal_code") != '') {
            url = url + '?postal_code=' + localStorage.getItem("postal_code");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryService, providedIn: "root" });
    return CategoryService;
}());
export { CategoryService };
