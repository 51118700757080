export const environment = {
  production: true,
  // baseURL: 'https://system.freshfishbasket.in/api/v1/',
  // siteURL: 'https://system.freshfishbasket.in/'
 // baseURL: 'http://localhost/ffb-dev/public/api/v1/',
  //baseURL: 'https://www.webappfactory.co/ffbdev/public/api/v1/'
  // baseURL: 'https://www.webappfactory.co/ffbdev/public/api/v1/',
  // siteURL: 'http://webappfactory.co/ffbdev/public/'
  baseURL: 'https://www.webappfactory.co/ffbnew/public/api/v1/',
  siteURL: 'https://www.webappfactory.co/ffbnew/public/'

};
