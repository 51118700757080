import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit , Inject,PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CartServiceService } from './services/cart-service.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ffb';
  public settingObj:any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,@Inject(WINDOW) private window: Window,
              public router: Router,
              private titleService: Title,
              private metaService: Meta,
              private cart:CartServiceService,
  ) {

  }

  ngOnInit() {
    this.titleService.setTitle("Fresh Fish Basket:Fresh Fish, Chicken, Mutton Online Home Delivery in Delhi. Gurugram and Kolkata");
    this.metaService.addTags([
      {name: 'title', content: "Fresh Fish Basket:Fresh Fish, Chicken, Mutton Online Home Delivery in Delhi. Gurugram and Kolkata"},

      {name: 'keywords', content: "Fish, fresh fish, buy fish online, Hilsa Fish, Rohu fish, Pomfret Fish, Chital Fish, Tiger Prawn, White Prawn, Crab, White Pomfret, Tangra Fish, Topshe Fish, Koi Fish, Kajali Fish, Salmon Fish, Blue Swimming Crab, Surmai Steak, Yellow Fin Tuna, Atlantic Salmon, Black Pomfret, Mutton Keema, Bata Fish, Pabda Fish, Tilapia Fish, Folui Fish, Ayar Fish, River Sole, Chicken, Kadaknath Chicken"},
      {name: 'description', content: "Fresh Fish Basket is your one-stop fresh fish and meat delivery shop at Delhi. Gurugram and Kolkata.You will get the Fresh Fish, Exotic Fish,Seafood, Meat,Ready to Eat, Ready to Fry, Marinated Fish & meat as well as grocery delivered straight to your doorstep. Now you can buy meat online anytime at your convenience. Indulge in our diverse selection: Fish, Seafood, Chicken, mutton & Marinated items. All our products are 100% Halal and Completely hygienic, natural and healthy. Once you've experienced Fresh Fish Basket, you'll forget the older ways of buying Fish and Meat."},
      {name: 'abstract', content: "Fresh Fish Basket is your one-stop fresh fish and meat delivery shop at Delhi. Gurugram and Kolkata."},
      {name: 'robots', content: 'index, follow'},
      {name: 'copyright', content: 'Copyright https://www.freshfishbasket.com All Rights Reserved'},
      {name: 'author', content: 'Bengal Fisheries and Seafood (OPC) Pvt. Ltd.'},
      {name: 'distribution', content: 'Global'},
      {name: 'rating', content: 'General'},
      {name: 'geo.placename', content: 'India'},
      {name: 'geo.position', content: '28.643999;77.118530'},
      {name: 'geo.region', content: 'In-'},
      {name: 'ICBM', content: '28.643999;77.118530'},
      {name: 'mobile-web-app-capable', content: 'true'},
      {property: 'og:image', content: 'https://www.freshfishbasket.in/assets/images/og_image.jpg'},
      {property: 'og:image:width', content: '1200'},
      {property: 'og:image:height', content: '630'},
      {property: 'og:image:alt', content: 'Buy Fresh Fish, chicken, mutton and grocery online in Delhi. Gurugram and Kolkata region and get delivered at your doorstep'},
      {property: 'og:description', content: "Fresh Fish Basket is your one-stop fresh fish and meat delivery shop at Delhi. Gurugram and Kolkata.You will get the Fresh Fish, Exotic Fish,Seafood, Meat,Ready to Eat, Ready to Fry, Marinated Fish & meat as well as grocery delivered straight to your doorstep. Now you can buy meat online anytime at your convenience. Indulge in our diverse selection: Fish, Seafood, Chicken, mutton & Marinated items. All our products are 100% Halal and Completely hygienic, natural and healthy. Once you've experienced Fresh Fish Basket, you'll forget the older ways of buying Fish and Meat."},
      {property: 'og:type', content: "website"},
      {property: 'og:url', content: "https://www.freshfishbasket.com"},
      {property: 'og:title', content: "Fresh Fish Basket:Fresh Fish, Chicken, Mutton Online Home Delivery in Delhi. Gurugram and Kolkata"}

    ]);


    this.router.events.subscribe(res=>{
      if (res instanceof NavigationEnd) {

        if (isPlatformBrowser(this.platformId)) {
          this.window.scrollTo(0, 0);
        }
      }
    })
 // Allows for ngOnInit to be called on routing to the same routing Component since we will never reuse a route
 this.router.routeReuseStrategy.shouldReuseRoute = function() {
  return false;
};
this.cart.getSettingData().then((resJson)=> {
  let result = (resJson as any);
  if (result.success == "true") {
     this.settingObj = result.setting;

  }
}).catch((error) => console.error(error));

  }

}
