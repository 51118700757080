import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContentService = /** @class */ (function () {
    function ContentService(_http) {
        this._http = _http;
        this.baseURL = environment.baseURL;
    }
    ContentService.prototype.getContentDetails = function (slug) {
        var _this = this;
        var url = this.baseURL + "cms/" + slug;
        var promise = new Promise(function (resolve, reject) {
            _this._http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    ContentService.prototype.getFaqDetails = function () {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/xml',
                'Authorization': 'jwt-token',
                'Access-Control-Allow-Origin': '*'
            })
        };
        var url = this.baseURL + "faq";
        var promise = new Promise(function (resolve, reject) {
            _this._http.get(url, httpOptions)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    ContentService.prototype.postContact = function (contact) {
        return this._http.post(this.baseURL + 'contact-us', contact)
            .pipe(map(function (res) {
            return res;
        }));
    };
    ContentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentService_Factory() { return new ContentService(i0.ɵɵinject(i1.HttpClient)); }, token: ContentService, providedIn: "root" });
    return ContentService;
}());
export { ContentService };
