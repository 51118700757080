import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { ChangePasswordComponent } from './core/change-password/change-password.component';
import { LoinGuardService }  from './services/loin-guard.service';

const routes: Routes = [ {
  path: '',
  loadChildren: './components/components.module#ComponentsModule'
},
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate:[LoinGuardService]
  },
  {
    path: '**',
    //redirectTo: '/external/404'
    redirectTo: '/'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
