import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  protected baseURL = environment.baseURL;
  constructor(private http:HttpClient) {
  }

  temporaryOrderSaveData(orderObj:Object) {
    // return this.http.post<any>(this.baseURL + 'temporary-order-save', orderObj)
    //   .pipe(map((res:any) => {
    //     return res;
    //   }));
      return this.http.post<any>(this.baseURL + 'latest-order-save', orderObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }
  //latest-order-save

  orderSaveData(orderObj:Object) {
    return this.http.post<any>(this.baseURL + 'order-save', orderObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  orderSaveCodData(orderObj:Object) {
    return this.http.post<any>(this.baseURL + 'order-save-cod', orderObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }


  getOrderData(userToken) {
    var that = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic '+ userToken)
    };

    var url = this.baseURL + "order-detail";

    let promise = new Promise((resolve, reject) => {
      this.http.get(url, header)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getOldOrderForReviewData(userToken) {
    var that = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic '+ userToken)
    };

    var url = this.baseURL + "order-review-data";

    let promise = new Promise((resolve, reject) => {
      this.http.get(url, header)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  skipOrderReview(userToken,orderNumber) {
    var that = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic '+ userToken)
    };

    var url = this.baseURL + "order-review-skip?order_number="+orderNumber;

    let promise = new Promise((resolve, reject) => {
      this.http.get(url, header)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  submitOrderReview(userToken,reviewObj:object) {
    var that = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic '+ userToken)
    };

    var url = this.baseURL + "submit-order-review";
    return this.http.post<any>(url, reviewObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  getOrderResponse(orderNumber) {
    var that = this;
    var url = this.baseURL + "order-response?orderNumber=" + orderNumber;
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  cancelOrder(orderNumber, token) {
    var that = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Basic '+token)
    };

    var url = this.baseURL + "cancel-order?order_number=" + orderNumber;

    let promise = new Promise((resolve, reject) => {
      this.http.get(url, header)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });

    return promise;
  }

  ajaxWalletBalance() {
    // return this.http.post<any>(this.baseURL + 'temporary-order-save', orderObj)
    //   .pipe(map((res:any) => {
    //     return res;
    //   }));
      return this.http.post<any>(this.baseURL + 'ajax-wallet-total', {})
      .pipe(map((res:any) => {
        return res;
      }));
  }
  getRatingData() {
    var url = this.baseURL+'get-overall-rating-feed';
  
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
}
