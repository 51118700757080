/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login-inner/login-inner.component.ngfactory";
import * as i3 from "../login-inner/login-inner.component";
import * as i4 from "../../services/login-registration.service";
import * as i5 from "@angular/router";
import * as i6 from "ng4-loading-spinner";
import * as i7 from "../../services/cart-service.service";
import * as i8 from "../../services/message.service";
import * as i9 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "gap100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-login-inner", [], null, null, null, i2.View_LoginInnerComponent_0, i2.RenderType_LoginInnerComponent)), i1.ɵdid(5, 114688, null, 0, i3.LoginInnerComponent, [i4.LoginRegistrationService, i5.ActivatedRoute, i6.Ng4LoadingSpinnerService, i7.CartServiceService, i8.MessageService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-md-5 offset-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "loginImg"], ["src", "assets/images/login-img.webp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "gap100"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i9.LoginComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i9.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
