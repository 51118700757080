import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CartServiceService = /** @class */ (function () {
    function CartServiceService(http) {
        this.http = http;
        this.cartChange = new Subject();
        this.baseURL = environment.baseURL;
    }
    CartServiceService.prototype.watch = function () {
        return this.cartChange.asObservable();
    };
    CartServiceService.prototype.cartBroadCastTypeOne = function (data) {
        this.cartChange.next(data);
    };
    CartServiceService.prototype.cartBroadCastTypeTwo = function (data) {
        this.cartChange.next(data.data);
        localStorage.setItem("cartToken", data.token);
    };
    CartServiceService.prototype.addCart = function (productId, productCuttingOptionId, userId, comment) {
        var _this = this;
        if (comment === void 0) { comment = null; }
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        var comment = ((comment != null || comment != '') ? comment : '');
        // alert(postalCode);
        //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
        if (productId) {
            var that = this;
            if (userId == null || userId == undefined) {
                console.log("not_login");
                var url = this.baseURL + "add-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("cartToken") + '&postal_code=' + postalCode + '&comment=' + comment + '&version=' + Math.random();
                if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
                    url = url + '&region_id=' + localStorage.getItem("region");
                }
                //alert(url);
                var promise = new Promise(function (resolve, reject) {
                    _this.http.get(url)
                        .toPromise()
                        .then(function (res) {
                        resolve(res);
                    }, function (msg) {
                        reject(msg);
                    });
                });
                return promise;
            }
            else {
                console.log("login");
                var that = this;
                var url = this.baseURL + "add-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("token") + '&postal_code=' + postalCode + '&comment=' + comment + '&version=' + Math.random();
                if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
                    url = url + '&region_id=' + localStorage.getItem("region");
                }
                var promise = new Promise(function (resolve, reject) {
                    _this.http.get(url)
                        .toPromise()
                        .then(function (res) {
                        resolve(res);
                    }, function (msg) {
                        reject(msg);
                    });
                });
                return promise;
            }
        }
    };
    CartServiceService.prototype.addNoti = function (productId, productCuttingOptionId, userId, comment) {
        var _this = this;
        if (comment === void 0) { comment = null; }
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        var comment = ((comment != null || comment != '') ? comment : '');
        // alert(postalCode);
        //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
        if (productId) {
            var that = this;
            if (userId == null || userId == undefined) {
                console.log("not_login");
                var url = this.baseURL + "add-notification?product_id=" + productId + '&token=' + localStorage.getItem("cartToken") + '&postal_code=' + postalCode;
                //alert(url);
                var promise = new Promise(function (resolve, reject) {
                    _this.http.get(url)
                        .toPromise()
                        .then(function (res) {
                        resolve(res);
                    }, function (msg) {
                        reject(msg);
                    });
                });
                return promise;
            }
            else {
                console.log("login");
                var that = this;
                var url = this.baseURL + "add-notification?product_id=" + productId + '&token=' + localStorage.getItem("token") + '&postal_code=' + postalCode;
                var promise = new Promise(function (resolve, reject) {
                    _this.http.get(url)
                        .toPromise()
                        .then(function (res) {
                        resolve(res);
                    }, function (msg) {
                        reject(msg);
                    });
                });
                return promise;
            }
        }
    };
    CartServiceService.prototype.productCheckInStock = function (productId, productCuttingOptionId) {
        var _this = this;
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        if (productId) {
            var that = this;
            var that = this;
            var url = this.baseURL + "check-in-stock?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&postal_code=' + postalCode;
            var promise = new Promise(function (resolve, reject) {
                _this.http.get(url)
                    .toPromise()
                    .then(function (res) {
                    resolve(res);
                }, function (msg) {
                    reject(msg);
                });
            });
            return promise;
        }
    };
    CartServiceService.prototype.addCouponInCart = function (couponCode, userId) {
        var _this = this;
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
        if (couponCode) {
            var that = this;
            if (userId == null) {
                var url = this.baseURL + "add-cart?coupon_code=" + couponCode + '&token=' + localStorage.getItem("cartToken") + '&postal_code=' + postalCode;
                if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
                    url = url + '&region_id=' + localStorage.getItem("region");
                }
                var promise = new Promise(function (resolve, reject) {
                    _this.http.get(url)
                        .toPromise()
                        .then(function (res) {
                        resolve(res);
                    }, function (msg) {
                        reject(msg);
                    });
                });
                return promise;
            }
            else {
                var that = this;
                var url = this.baseURL + "add-cart?coupon_code=" + couponCode + '&token=' + localStorage.getItem("token") + '&postal_code=' + postalCode;
                if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
                    url = url + '&region_id=' + localStorage.getItem("region");
                }
                var promise = new Promise(function (resolve, reject) {
                    _this.http.get(url)
                        .toPromise()
                        .then(function (res) {
                        resolve(res);
                    }, function (msg) {
                        reject(msg);
                    });
                });
                return promise;
            }
        }
    };
    CartServiceService.prototype.removeCart = function (productId, productCuttingOptionId) {
        var _this = this;
        //alert("herennnn");
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
        if (localStorage.getItem("cartToken") != '' && localStorage.getItem("cartToken") != 'null' && localStorage.getItem("cartToken") != null) {
            var url = this.baseURL + "remove-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("cartToken") + '&postal_code=' + postalCode + '&version=' + Math.random();
            if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
                url = url + '&region_id=' + localStorage.getItem("region");
            }
            var promise = new Promise(function (resolve, reject) {
                _this.http.get(url)
                    .toPromise()
                    .then(function (res) {
                    resolve(res);
                }, function (msg) {
                    reject(msg);
                });
            });
            return promise;
        }
        else {
            var url = this.baseURL + "remove-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("token") + '&postal_code=' + postalCode + '&version=' + Math.random();
            if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
                url = url + '&region_id=' + localStorage.getItem("region");
            }
            var promise = new Promise(function (resolve, reject) {
                _this.http.get(url)
                    .toPromise()
                    .then(function (res) {
                    resolve(res);
                }, function (msg) {
                    reject(msg);
                });
            });
            return promise;
        }
    };
    CartServiceService.prototype.updateCartQuantity = function (productId, productCuttingOptionId, quantity) {
        var _this = this;
        var cartSelectedAddressId = '';
        if (localStorage.getItem("cartSelectedAddressId") != undefined) {
            cartSelectedAddressId = localStorage.getItem("cartSelectedAddressId");
        }
        var token = null;
        if (localStorage.getItem("cartToken") != '' && localStorage.getItem("cartToken") != 'null' && localStorage.getItem("cartToken") != null) {
            token = localStorage.getItem("cartToken");
        }
        else {
            token = localStorage.getItem("token");
        }
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
        var url = this.baseURL + "update-quantity?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&quantity=' + quantity + "&token=" + token + '&postal_code=' + postalCode + '&version=' + Math.random();
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
            url = url + '&region_id=' + localStorage.getItem("region");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    /*cartAllProduct(newUserToken) {
      var that = this;
      var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
  
      var callUrl = this.baseURL + "cart-detail?token=" + newUserToken+'&postal_code='+postalCode;
  
      return this.http.get<any>(callUrl);
    }*/
    CartServiceService.prototype.cartAllProduct = function (newUserToken) {
        var _this = this;
        var that = this;
        var postalCode = ((localStorage.getItem("postal_code") != null) ? localStorage.getItem("postal_code") : '');
        var url = this.baseURL + "cart-detail?token=" + newUserToken + '&postal_code=' + postalCode;
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
            url = url + '&region_id=' + localStorage.getItem("region");
        }
        //return this.http.get<any>(callUrl);
        /*.pipe(map((response:any) => {
          return response;
        }));*/
        /*.subscribe(
          data => {
            console.log("Success response");
            this.cartChange.next(data);
          },
          error => {
            console.log('Error: Cart items is empty ....');
          }
        );*/
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                console.log("Success response");
                resolve(res);
                that.cartChange.next(res);
            }, function (msg) {
                console.log("Error response");
                reject(msg);
            });
        });
        console.log('Return promise');
        return promise;
    };
    CartServiceService.prototype.cartSummary = function (userToken, order_customer_address_id) {
        var _this = this;
        var that = this;
        var url = this.baseURL + "cart-summary?token=" + userToken + "?order_customer_address_id=" + order_customer_address_id;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
                that.cartChange.next(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    CartServiceService.prototype.getSettingData = function () {
        var _this = this;
        var that = this;
        var url = this.baseURL + "setting";
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    CartServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartServiceService_Factory() { return new CartServiceService(i0.ɵɵinject(i1.HttpClient)); }, token: CartServiceService, providedIn: "root" });
    return CartServiceService;
}());
export { CartServiceService };
