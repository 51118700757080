<header>
  <div class="container-fluid">
    <div class="headerInfo clearfix">
      <div class="float-left">
        <div>
          <b *ngIf="settingObj">
            <img src="assets/images/icons/phone.svg" class="headerInfoCallIcon">
            <a class="black" href="tel:{{settingObj.phone}}">
              {{settingObj.phone}} (9:00am to 7:00pm) (Call for Order)
            </a>
          </b>
          <span class="offDayDeclaration" *ngIf="regionId==1">NO DELIVERY ON TUESDAY ( ONLINE ORDER ACCEPTED )</span>
          <br>
          <b *ngIf="settingObj">
            <img src="assets/images/icons/phone.svg" class="headerInfoCallIcon">
            <a class="black" href="tel:{{settingObj.phone}}">
              {{settingObj.whatsapp_phone}} (9:00am to 7:00pm) (Query)
            </a>
          </b>
        </div>
      </div>
      <div class="float-right">
        <!--<a class="topNavBulkOrder" [routerLink]="['/bulk-order']">BULK ORDER</a>-->
        <span>Download the app</span>
        <div class="downloadApp"><a href="https://apps.apple.com/us/app/id1509173284" target="_blank"><img src="assets/images/icons/apple.svg" class="appStoreIcons"/> App Store</a></div>
        <div class="downloadApp"><a href="https://play.google.com/store/apps/details?id=com.app.freshfishbasket" target="_blank"><img src="assets/images/icons/google-play.svg" class="appStoreIcons"/> Play Store</a></div>
        <br>
        <div class="text-right">
          <span class="locSelectedName" *ngIf="siteRegion=='1'"> DELHI & GURGAON</span>
          <span class="locSelectedName" *ngIf="siteRegion=='2'"> KOLKATA</span>&nbsp;&nbsp;
          <a href="javascript:void(0);" class="topNavBulkOrder" (click)="openBulkOrder();">BULK ORDER</a>
        </div>
        
      </div>
    </div>
  </div>
  <nav class="container-fluid clearfix headerNav">

    <div class="headerMenuSmLinksHolder">
        <!-- <a class="headerMenuSmCart" [routerLink]="['/cart']"> -->

        <!-- <a class="headerMenuLocation">
          <img src="assets/images/icons/map-pointer.svg"/>
        </a> -->
        <a href="javascript:;" class="mobsearchCaller" (click)="openSearchBox()"><img src="assets/images/icons/search.svg"/></a>
        <a href="javascript:;" *ngIf="showCart==true" class="headerMenuSmCart openCart">
          <label>
            <img src="assets/images/icons/bag.svg" class="btn-icon"/>
            <span class="navButtonLabel">{{totalItems}}</span>
          </label>
        </a>
        <a class="headerMenuSm">
          <img src="assets/images/icons/menu-button.svg"/>
        </a>
    </div>


    <div class="float-left">
      <a href="javascript:;" class="headerLogo" [routerLink]="['/']">
        <img src="assets/images/logo.png" alt="Fresh Fish Basket"/>
      </a>
      <div class="navSearch hideSearchOnMob">
        <form (ngSubmit)="submitSearchForm()" id="submitSearchForm">
          <div class="navSearchInner">
          <div class="navSearchText">
            <img src="assets/images/icons/search.svg" class="navSearchIcon"/>
            <input type="text" placeholder="Search" [(ngModel)]="search.productName" name="productName"/>
          </div>

          <div class="deliveryLoc">
            <div class="deliveryLocIn">
              <div *ngIf="showPostalCode">
                <img src="assets/images/icons/map-pointer.svg"/>
                Deliver here <ng-container *ngIf="showPostalCode">{{showPostalCode}}</ng-container>
              </div>
              <div>
                <p class="deliveryLocation">{{selectCurrentAddress}}
                </p>
                <img src="assets/images/icons/caret-down.svg" class="navCaretDown"/>
              </div>
            </div>
          </div>

          <button class="searchSubmit" type="submit"
                  style="background-image: url('assets/images/icons/right-arrow-forward-blue.svg');"></button>
        </div>
        </form>
      </div>

      <!-- <a href="javascript:;" class="openDeliveryCaller"> open delivery</a> -->
      <!-- <a href="javascript:;" id="timeSlotOpen"> open time slot</a> -->
    </div>
    <div class="float-right">
      <div class="navRight">
        <div class="navButtons">
          <!-- <a *ngIf="cartData.length==0" href="javascript:;" class="btn btn-primary" (click)="regionCaller()">Select City</a> 29.01.21 -->
          <a href="javascript:;" *ngIf="showCart==true" class="btn btn-primary openCart">&nbsp;
            &nbsp;
            CART
            <label>
              <img src="assets/images/icons/bag.svg" class="btn-icon"/>
              <span class="navButtonLabel">{{totalItems}}</span>
            </label>
            &nbsp;
          </a>
          <a href="javascript:;" class="btn btn-primary openLoginModal" *ngIf="!loggedIn">&nbsp; LOGIN &nbsp;</a>
        </div>
        <a href="javascript:;" [routerLink]="['/account']" class="userMenu usermanuCustom" *ngIf="loggedIn">
          <div class="userMenuIn" [style.background-image]="'url(' + profileImgPreview +')'">
          </div><span class="acntHoldName">My Account</span>
          <div class="userMenuDrop">
            <div class="userMenuDropIn">
              <a href="javascript:;" [routerLink]="['/account']">Account</a>
              <a href="javascript:;" [routerLink]="['/orders']">Orders</a>
              <!--<a href="javascript:;" [routerLink]="['/change-password']">Change Password</a>-->
              <a href="javascript:;" (click)="logout()">Logout</a>
            </div>
          </div>
        </a>
      </div>
    </div>
<!-- for mobiel search -->
    <div class="navSearchSm">
      <form (ngSubmit)="submitSearchForm()" id="submitSearchForm">
        <div class="navSearchInner">
        <div class="navSearchText">
          <!-- <img src="assets/images/icons/search.svg" class="navSearchIcon"/> -->
          <input type="text" placeholder="Search" [(ngModel)]="search.productName" name="productName"/>
        </div>
        <button class="searchSubmit" type="submit"
                style="background-image: url('assets/images/icons/right-arrow-forward-blue.svg');"></button>
      </div>
      </form>
    </div>
  </nav>
  <nav class="mainNav">
    <div class="mainNavInner">
      <div *ngFor="let catMenu of categoriesMenu"><!--navItems-->
        <!--<a href="javascript:;" [ngClass]="{'heroParent': true}" (click)="searchProductNav(catMenu?.slug);">{{
          catMenu?.title }}</a>-->
        <a href="javascript:;" [ngClass]="{'heroParent': true}" [routerLink]="['/products/'+catMenu?.slug]">{{
          catMenu?.title }}</a>
        <!--<div class="heroMenu">
          <div class="heroMenuHeader">{{ catMenu?.title }}</div>
          <div class="heroMenuContent">
            <div class="row">
              <div class="col-md-4" *ngFor="let product of catMenu?.productItem">
                <a href="javascript:;" class="heroItemLink" [routerLink]="['/product',product?.slug]">
                  <span class="heroItemImage"
                        [ngStyle]="{'background-image': 'url(' + product?.defaultImage + ')'}"></span>
                  <p class="heroItemName">{{ product.title }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>-->
      </div>

      <!--<div>
        <a href="javascript:;" [routerLink]="['/recipes']" [ngClass]="{'heroParent': false}">Recipes</a>
      </div>-->
    </div>
  </nav>
</header>

<div class="mobileMenu">
  <div class="mmUser">
    <div style="background-image: url('assets/images/icons/go-back-left-arrow.svg')" class="closeMm"></div>

    <!-- <div class="userMenu" *ngIf="loggedIn">
      <div class="userMenuIn" [style.background-image]="'url(' + profileImgPreview +')'">
      </div> -->


    <div class="mmUserImage" *ngIf="loggedIn"
    [style.background-image]="'url(' + profileImgPreview +')'"></div>
    <ng-container *ngIf="!loggedIn">
      <div>
        <a href="javascript:;" class="btn btn-primary openLoginModal">LOGIN</a>
      </div>
    </ng-container>
    <div *ngIf="loggedIn" class="mmUserName">{{newProfileName}}</div>
    <a *ngIf="loggedIn" href="javascript:;" [routerLink]="['/account']" class="mmUserViewProfile" >View Profile</a>
  </div>
  <div class="navLocation">
    <div><img src="assets/images/icons/map-pointer.svg"> Deliver here <ng-container *ngIf="showPostalCode">{{showPostalCode}}</ng-container></div>
    <div>
      <p class="deliveryLocation">{{selectCurrentAddress}} <img class="navCaretDown"
                                                                src="assets/images/icons/caret-down.svg">
      </p>
    </div>
  </div>
  <div class="gap10"></div>
  <div class="mmItems">
    <ng-container *ngFor="let catMenu of categoriesMenu">
      <div>
        <a href="javascript:;" [routerLink]="['/products',catMenu?.slug]">{{
          catMenu?.title }}</a>
      </div>
    </ng-container>
    <!-- <div>
      <a href="javascript:;" [routerLink]="['/recipes']">Recipes</a>
    </div> -->
  </div>
  <hr/>
  <div class="mmItems">
    <ng-container *ngIf="!loggedIn">
      <div>
        <a href="javascript:;" class="btn btn-primary openLoginModal" [routerLink]="['/account']">LOGIN</a>
      </div>
    </ng-container>
    <ng-container *ngIf="loggedIn">
      <div>
        <a href="javascript:;" [routerLink]="['/account']">Account</a>
      </div>
      <div>
        <a href="javascript:;" [routerLink]="['/orders']">Orders</a>
      </div>
      <div>
        <a href="javascript:;" (click)="logout()">Logout</a>
      </div>
    </ng-container>
  </div>
  <hr/>
  <div class="container-fluid mmContactNumbers">
    <div class="row" *ngIf="settingObj">
      <div class="col-md-6">
        <a href="tel:{{settingObj.phone}}">
          <img src="assets/images/icons/phone-receiver.svg"/> {{settingObj.phone}} (10:00am - 9:00pm)
        </a>
      </div>
      <!-- <div class="col-6">
        <a href="javascript:;">
          <img src="assets/images/icons/whatsapp.svg"/> +91-881-063-6196
        </a>
      </div> -->
    </div>
  </div>
  <p class="tuesdayDisclaimer">NO DELIVERY ON TUESDAY ( ONLINE ORDER ACCEPTED )</p>
  <div class="menuAppBadgesHolder">
    <!-- <div class="row">
      <div class="col-6">
        <a href="javascript:;">
          <img src="assets/images/app-store-badge.png"/>
        </a>
      </div>
      <div class="col-6">
        <a href="javascript:;">
          <img src="assets/images/google-play-badge.png"/>
        </a>
      </div>
    </div> -->
    <div class="container-fluid">
      <a *ngIf="cartData.length==0" href="javascript:;" class="btn btn-primary btn-sm" (click)="regionCaller()">Select City</a>
      <div class="gap10"></div>
      <a href="javascript:void(0);" class="btn btn-block btn-outline-primary btn-sm" (click)="openBulkOrder();">BULK ORDER</a>
    </div>
  </div>
</div>
<div class="mobileMenuOverlay"></div>

<div class="selectLocation">
  <div class="selectLocationInner">
    <div class="selectLocationContent">
      <div class="selectLocationHeader">
        Choose delivery location
        <a href="javascript:;" class="closeSelectLocation" (click)="closeLocationModal();"><img src="assets/images/icons/close.svg"/></a>
      </div>
      <div class="selectLocationCitiesHolder" *ngIf="siteRegion=='1'">
        <a href="javascript:;" class="active">Delhi (Central)</a>
        <a href="javascript:;">Delhi (North West)</a>
        <a href="javascript:;">Delhi (West)</a>
        <a href="javascript:;">Delhi (South West)</a>
        <a href="javascript:;">Delhi (South)</a>
        <a href="javascript:;">Gurgaon (Spanish Court)</a>
        <a href="javascript:;">Gurgaon</a>
        <a href="javascript:;">Gurgaon (Sun City & City Court)</a>
        <a href="javascript:;">Gurgaon (Eros City)</a>
        <!-- <a href="javascript:;">Chandigarh</a>
        <a href="javascript:;">Panchkula</a>
        <a href="javascript:;">Mohali</a>
        <a href="javascript:;">Mumbai</a>
        <a href="javascript:;">Pune</a>
        <a href="javascript:;">Chennai</a> -->
      </div>
      <div class="selectLocationCitiesHolder" *ngIf="siteRegion=='2'">
        <a href="javascript:;" class="active">South Kolkata</a>
        <a href="javascript:;">North Kolkata</a>
        <a href="javascript:;">Salt Lake</a>
        <a href="javascript:;">New Town</a>
        <a href="javascript:;">Howrah</a>
        <!-- <a href="javascript:;">Chandigarh</a>
        <a href="javascript:;">Panchkula</a>
        <a href="javascript:;">Mohali</a>
        <a href="javascript:;">Mumbai</a>
        <a href="javascript:;">Pune</a>
        <a href="javascript:;">Chennai</a> -->
      </div>
      <div class="selectLocationInput">
        <div class="locationInputHolder">

          <div class="ng-autocomplete">
            <ng-autocomplete
              [data]="data"
              [searchKeyword]="keyword"
              (selected)='selectEvent($event)'
              (inputChanged)='onChangeSearch($event)'
              (inputFocused)='onFocused($event)'
              [itemTemplate]="itemTemplate"
              [(ngModel)]="pinCodeCurrent"
              placeHolder="Search Postal Code..."
              autocomplete="off">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.code_new"></a>
            </ng-template>
          </div>

          <img src="assets/images/icons/search.svg"/>
        </div>
        <a href="javascript:;" class="getGPS" (click)="fetchCurrentLocation();"><img
          src="assets/images/icons/gps-fixed-indicator.svg"/> GPS</a>
      </div>
      <ng-container *ngIf="errorText">
        <div class="loc-error" style="display:block;color: rgb(255, 255, 255);font-size: 14px; background: rgb(224, 25, 48);border-radius: 0px 0px 4px 4px;
    padding: 15px 40px;border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
          <img src="https://d2407na1z3fc0t.cloudfront.net/Banner/loc-err.png" alt="">Unfortunately, We do not serve your locality
        </div>
      </ng-container>

    </div>
  </div>
</div>
<div class="locationOverlay"></div>

<diV class="loginModal">
  <div class="lmInner">
    <a href="javascript:;" class="closeLM">
      <img src="assets/images/icons/close.svg"/>
    </a>
    <div class="lmIn">
      <div class="loginFlItem lmflImg" style="background-image: url('assets/images/login-img.png')">
      </div>
      <div class="loginFlItem lmflContent">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <app-login-inner></app-login-inner>
          </div>
        </div>
      </div>
    </div>
  </div>
</diV>
<diV class="loginModalOverlay"></diV>

<div class="cartModal">
  <div class="cartInner">
    <a href="javascript:;" class="closeCart">
      <img src="assets/images/icons/close.svg"/>
    </a>
    <div class="cartIn" *ngIf="totalItems>0">
      <div class="cartTop">

        <div class="clearfix">
          <div class="float-left">
            <h2 class="pageCrtHeader">Cart</h2>
          </div>
          <div class="float-right">
          </div>
        </div>

        <div class="cartItems">
          <ng-container *ngIf="settingObj.cod==0 && needAmountForFreeDelivery && shippingCost!=0">
            <div class="freeDelProgressHolder">
              <div class="fdHdr">{{needAmountForFreeDelivery| currency:"&#8377;"}} away from FREE SHIPPING</div>
              <div class="freeDelProgressIn">
                <div class="freeDelProgressMin">₹0</div>
                <div class="freeDelProgressBar">
                  <span style="width:50%;"></span>
                </div>
                <div class="freeDelProgressMax">{{minCodAmount| currency:"&#8377;"}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <p style="color: #F00!important;" *ngIf="regionId==1">We are currently not accepting COD due to Covid-19 outbreak.</p>
          </ng-container>
          <ng-container *ngFor="let cartItem of cartData ; let i = index">
            <div class="cartItem" *ngIf="cartItem?.product_id!=null">
              <a href="javascript:;" class="cartItemImage"
                 [ngStyle]="{'background-image': 'url(' + cartItem?.product_details?.product_cutting_option?.defaultImage + ')'}" title="{{ cartItem?.product_details?.product_cutting_option?.name }}"></a>
              <div class="cartContent row align-items-start">
                <div class="col-md-7">
                  <!-- <a [routerLink]="['/product',cartItem?.product_details?.slug]" class="cartItemName">{{ cartItem?.product_details?.product_cutting_option?.name
                    }}</a> -->
                    <p class="cartItemName">{{ cartItem?.product_details?.product_cutting_option?.name
                    }}</p>
                  <div *ngIf="cartItem.comment!='' && cartItem.comment!='null'" class="customNoteOnCart">Note: <span>{{cartItem.comment}}</span></div>
                  <a href="javascript:;" class="removeCartItem"
                     (click)="removeCartItem(cartItem?.product_id,cartItem?.product_cutting_option_id);">REMOVE</a>

                </div>
                <div class="col-md-5">
                  <div class="cartItemCountHold">
                    <button href="javascript:;" class="cartCountLess"
                            (click)="quantityUpdate(cartItem?.product_id,cartItem?.product_cutting_option_id,cartItem?.quantity,0,i);" [disabled]="updateDisabled" >-</button>
                    <input type="number" [(ngModel)]="cartItem.quantity" min="1" (input)="quantityUpdate(cartItem?.product_id,cartItem?.product_cutting_option_id,cartItem?.quantity,3,i);" [disabled]="true" />
                    <button href="javascript:;" class="cartCountMore"
                       (click)="quantityUpdate(cartItem?.product_id,cartItem?.product_cutting_option_id,cartItem?.quantity,1,i);" [disabled]="updateDisabled">+</button>
                  </div>
                  <p class="cartItemPrice">Subtotal: <span>{{ cartItem?.itemTotalPrice | currency:"&#8377;"}}</span></p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="cartBottom">

        
        <ng-container *ngIf="settingObj.coupon==1 && siteRegion==1">
          <div class="gap10"></div>
          <div class="couponHolder" *ngIf="apply_coupon_code==''">
            <input type="text" placeholder="Enter Coupon code" name="coupon_code" [(ngModel)]="coupon_code" autocomplete="off"/>
            <button (click)="applyCouponCode(1);">APPLY</button>
          </div>
        </ng-container>

        <ng-container *ngIf="settingObj.coupon_kolkata==1 && siteRegion==2">
          <div class="gap10"></div>
          <div class="couponHolder" *ngIf="apply_coupon_code==''">
            <input type="text" placeholder="Enter Coupon code" name="coupon_code" [(ngModel)]="coupon_code" autocomplete="off"/>
            <button (click)="applyCouponCode(1);">APPLY</button>
          </div>
        </ng-container>

        <div class="gap10"></div>

        <div class="cartDetails">
          <div class="row alignCenter">
            <div class="col-sm-8">
              <div class="cartTot">
                <p>Total: {{ totalPrice | currency:"&#8377;" }}</p>
                <small class="delCh" *ngIf="shippingCost!=0">Delivery charge ({{deliveryChargeMsg}}): {{ shippingCost | currency:"&#8377;" }}</small>
                <small *ngIf="shippingCost==0">Delivery charge: {{ shippingCost | currency:"&#8377;" }}</small>

                <div class="clearfix" *ngIf="couponPrice>0">
                  <div class="">
                    <small class="coupon" *ngIf="couponPrice>0" (click)="applyCouponCode(0);">Coupon applied  ({{apply_coupon_code}})  (-){{couponPrice | currency:"&#8377;"}}</small>
                  </div>
                  <div class="">
                    <a href="javascript:;" class="removeCoupon" (click)="applyCouponCode(0);">Remove Coupon</a>
                  </div>
                </div>
                <p>Final Price: {{ finalPrice | currency:"&#8377;" }}</p>
              </div>
            </div>
            <div class="col-sm-4">
              <ng-container *ngIf="gloceryFlag==0">
                <ng-container *ngIf="(totalPrice-couponPrice)>=
                  settingObj.shipping_min_amount">
                  <ng-container *ngIf="totalPrice>0 && loggedIn==true">
                    <a href="javascript:;" class="btn btn-primary btn-block btn-lg gotoSelectAddress btnCheckout"
                     (click)="checkOutAddress();">CHECKOUT</a>
                  </ng-container>

                  <ng-container *ngIf="totalPrice<0.01 && loggedIn==true">
                    <a href="javascript:;" class="btn btn-primary btn-block btn-lg" disabled>CHECKOUT</a>
                  </ng-container>

                  <ng-container *ngIf="!loggedIn">
                    <a href="javascript:;" class="btn btn-primary btn-block btn-lg toggleLoginModal">CHECKOUT</a>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="gloceryFlag==1">
                <ng-container *ngIf="(totalPrice-couponPrice)>=
                  groceryMinAmount">
                  <ng-container *ngIf="totalPrice>0 && loggedIn==true">
                    <a href="javascript:;" class="btn btn-primary btn-block btn-lg gotoSelectAddress btnCheckout"
                    (click)="checkOutAddress();">CHECKOUT</a>
                  </ng-container>

                  <ng-container *ngIf="totalPrice<0.01 && loggedIn==true">
                    <a href="javascript:;" class="btn btn-primary btn-block btn-lg" disabled>CHECKOUT</a>
                  </ng-container>

                  <ng-container *ngIf="!loggedIn">
                    <a href="javascript:;" class="btn btn-primary btn-block btn-lg toggleLoginModal">CHECKOUT</a>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="gloceryFlag==0">
                <ng-container  *ngIf="(totalPrice-couponPrice)<
                settingObj.shipping_min_amount">
                <a href="javascript:;" class="btn btn-primary btn-block btn-lg gotoSelectAddress btnCheckout" [routerLink]="['/']">ADD MORE PRODUCT</a>

              </ng-container>
              </ng-container>

              <ng-container *ngIf="gloceryFlag==1">
                <ng-container  *ngIf="(totalPrice-couponPrice)<
                  groceryMinAmount">
                  <a href="javascript:;" class="btn btn-primary btn-block btn-lg gotoSelectAddress btnCheckout" [routerLink]="['/']">ADD MORE PRODUCT</a>
              </ng-container>
              </ng-container>


            </div>

            <div class="col-sm-12">
              <ng-container *ngIf="gloceryFlag==0">
                <ng-container  *ngIf="(totalPrice-couponPrice)<
                settingObj.shipping_min_amount">
                <p style="color: #F00!important;">We are currently not accepting order less then amount {{settingObj.shipping_min_amount | currency:"&#8377;"}}.</p>
              </ng-container>
              </ng-container>
              <ng-container *ngIf="gloceryFlag==1">
                <ng-container  *ngIf="(totalPrice-couponPrice)<
                  groceryMinAmount">
                <p style="color: #F00!important;">We are currently not accepting order less then amount {{groceryMinAmount | currency:"&#8377;"}} for only grocery  purchase.</p>
              </ng-container>
              </ng-container>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="empty_cart_wrap" *ngIf="totalItems==0">
      <img src="assets/images/empty-cart.svg"/>
      <p>Your cart is empty</p>
    </div>
  </div>
</div>
<div class="cartModalOverlay"></div>

<div class="bulkOrderModal">
  <div class="boInner">
    <a href="javascript:;" class="closeBo" (click)="dismissBulkOrder();">
      <img src="assets/images/icons/close.svg"/>
    </a>
    <div class="boIn">
      <div class="boTop" style="background-image: url('assets/images/icons/bulk.svg');"></div>
      <div class="boBottom">
        <p class="boHeader">Bulk order</p>

        <div class="boPhone" *ngIf="pageStatus == 'otp'">
          <form (ngSubmit)="submitGenerateOtpForm()" id="generateOtpForm">
              <div class="customForm">
                <img src="assets/images/icons/profile.svg"/>
                <input type="text" placeholder="Enter your phone number" id="generate_otp_phone" name="phone" maxlength="10"
                       [(ngModel)]="generate_otp.phone" required/>
              </div>
            <button type="submit" class="btn btn-block btn-primary">GET OTP</button>
          </form>
        </div>

        <div class="boOtp" *ngIf="pageStatus == 'verifyOtp'">
          <form (ngSubmit)="submitOtp()" id="submitOtp">
          <div class="customForm">
            <img src="assets/images/icons/profile.svg"/>
            <input type="text" placeholder="Enter your phone number" id="otp_login_phone" name="phone"
                   [(ngModel)]="bulkOrderObj.phone" required/>
          </div>
          <div class="customForm">
            <img src="assets/images/icons/padlock.svg"/>
            <input type="password" placeholder="Enter OTP" id="otp_login_otp" name="otp"
                   [(ngModel)]="bulkOrderObj.otp" required />
          </div>
          <button type="submit" class="btn btn-block btn-primary">VERIFY TO PROCEED</button>
          </form>

          <div class="gap10"></div>
          <div class="otpMoreOpt clearfix">
            <div class="float-left">
              <a href="javascript:;" (click)="gotoPhoneNum()">CHANGE PHONE NUMBER</a>
            </div>
          </div>

        </div>

        <div class="boOrder" *ngIf="pageStatus == 'submitBulkOrder'">
          <form (ngSubmit)="submitBulkOrder()" id="submitBulkOrder">
          <div class="customForm">
            <img src="assets/images/icons/product.svg"/>
            <input type="text" placeholder="Product name" id="product_name" name="product_name"
                   [(ngModel)]="bulkOrderObj.product_name" required/>
          </div>
          <div class="customForm">
            <img src="assets/images/icons/quantity.svg"/>
            <input type="number" placeholder="Quantity (Min 10kg)" id="quantity" name="quantity"
                   [(ngModel)]="bulkOrderObj.quantity" min="10" max="100" required/>
          </div>
          <div class="customForm">
            <img src="assets/images/icons/date.svg"/>
            <my-date-picker readonly placeholder="Date of delivery" name="deliveryDate" [options]="myDatePickerOptions" [(ngModel)]="bulkOrderObj.delivery_date" required></my-date-picker>
          </div>
          <button type="submit" class="btn btn-block btn-primary">PLACE ORDER</button>
          </form>
          <div class="gap10"></div>
          <div class="otpMoreOpt clearfix">
            <div class="float-left">
              <a href="javascript:;" (click)="gotoPhoneNum()">CHANGE PHONE NUMBER</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bulkOrderOverlay"></div>


<!-- Time Slot Modal -->

<div class="timeSlotWrapper">
  <div class="timeSlotInner">
    <a href="javascript:;" class="closeTimeSlot"><img src="assets/images/icons/close.svg"/></a>
    <div class="timeSlotContent">
      <img lazyload="assets/images/time_slot_modal_img.png" src="" alt="" class="timeSlotImg">
      <div class="timeSlotHeader">
        <h2 class="float-left">Available Delivery Slot</h2>
        <div class="timeSlotPostalInputHolder text-right">
          <label>Postal Code : {{showPostalCode}}</label>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="selectASlot selectASlotModal">
        <div class="slotHolder">
          <ng-container *ngIf="!slots || slots.length==0">
            <p style="color: #F00!important;">{{slotMsg}}</p>
          </ng-container>
          <div class="slotRow">
            <div class="slotCol" *ngFor="let slot of slots; let i = index">
                <div class="slotItem" >
                  <img src="{{slot.icon}}"/>
                  <div>{{slot.text+ " (" + slot.day_name + ")"}} </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="timeSlotOverlay"></div>

<!-- Review Modal -->




<!-- <div class="DeliveryAvailableModal">
  <div class="timeSlotInner">
    <a href="javascript:;" class="DeliveryAvail"><img src="assets/images/icons/close.svg"/></a>
    <div class="DeliveryContent">
      <img src="assets/images/delivery_modal_img.png" alt="" class="DeliveryBannertImg">
      <h1 class="delHeader text-center">Live Delivery Updates - Bangalore</h1>
      <h3 class="delSubHead text-center">As of today, We are delivering in the following areas in Bangalore</h3>
      <div class="gap20"></div>
      <div class="prInfoPointsHolder">
        <div>
          <h4>Bell Road</h4>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit reprehenderit, totam id omnis itaque repellendus quasi consectetur quisquam, ab laborum enim animi tempora architecto ipsam maxime, voluptate corrupti dignissimos recusandae!</p>
        </div>
      </div>
      <div class="prInfoPointsHolder">
        <div>
          <h4>Bell Road</h4>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit reprehenderit, totam id omnis itaque repellendus quasi consectetur quisquam, ab laborum enim animi tempora architecto ipsam maxime, voluptate corrupti dignissimos recusandae!</p>
        </div>
      </div>
      <div class="prInfoPointsHolder">
        <div>
          <h4>Bell Road</h4>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit reprehenderit, totam id omnis itaque repellendus quasi consectetur quisquam, ab laborum enim animi tempora architecto ipsam maxime, voluptate corrupti dignissimos recusandae!</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="DeliveryAvailableModalOverlay"></div> -->

<a *ngIf="loggedIn &&  settingObj.referral==1 && referralCode!=''" href="javascript:;" class="shareReferCaller" (click)="openRefBox()"><img lazyLoad="assets/images/icons/refer.svg" src="" alt=""> Refer & Earn</a>
<div class="shareReferHolder">
  <a href="javascript:;" class="rightCloseArrow" (click)="closeRefBox()"><img lazyLoad="assets/images/icons/right-arrow.png" src="" alt=""></a>
  <img class="refIcon" lazyLoad="assets/images/icons/refer-blue.png" src="" alt="">
  <h4>Refer and Earn</h4>
  <p>Earn {{settingObj?.referral_amount}} points by sharing your unique referral code with friends and family</p>
  <!-- <div class="refereCode"><input class="refereCode" readonly type="text" value="{{referralCode}}" #userinput> <button (click)="copyReferral(userinput)" class="copy">
    Copy Code</button></div> -->
    <div  class="refercodeWrap"><input class="refereCode" readonly="" type="text" value="{{referralCode}}" #userinput><button (click)="copyReferral(referralCodeWithMessage)" class="copy"> <img src="assets/images/icons/sheet.svg"></button></div>
    <!-- <input type="tel" value="{{referralCode}}" #userinput> -->
  <p>You will earn points, once referral place first order using referral code</p>
  <a class="learnMore" (click)="closeRefBox()" [routerLink]="['/page/refer-and-earn']">Learn more</a>
  <br>
</div>

<div class="reviewContWrapper">
  <div class="reviewContInner">
    <div class="reviewContent">
      <div class="timeSlotHeader">
        <h2 class="locSelHeaer">Please select your city</h2>
        <a href="javascript:;" class="closeTimeSlot closeRevMod"><img src="assets/images/icons/close.svg"/></a>
        <div class="clearfix"></div>
      </div>
      <div class="reviewContBody locSelBody">
        <a href="javascript:;" class="locSelector {{siteRegion=='1' ? 'active':''}}" (click)="changeRegion('1')">
          <img lazyLoad="assets/images/india-gate.png" src="" alt="Delhi" class="locPlImg">
          <span class="plName">Delhi & Gurgaon</span>
        </a>
        <a href="javascript:;" class="locSelector {{siteRegion=='2' ? 'active':''}}" (click)="changeRegion('2')">
          <img lazyLoad="assets/images/victoria.png" alt="Kolkata" class="locPlImg">
          <span class="plName">Kolkata</span>
        </a>
        <!-- <div class="downloadApp"><a href="javascript:;" (click)="changeRegion('1')"> Delhi</a></div>
        <div class="downloadApp"><a href="javascript:;" (click)="changeRegion('2')">Kolkata</a></div> -->
      </div>
    </div>
  </div>
</div>
<div class="reviewContWrapperOverlay"></div>
