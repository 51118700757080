import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { OrderService } from '../../services/order.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public settingObj:any;
  public content: any;
  public slug = "footer";
  public sub: any;
  public overallRating:any;
  public ratingData:any;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private _content: ContentService,
    private ActivatedRoute: ActivatedRoute,
    private orderService:OrderService
  ) {
    this.settingObj = JSON.parse(localStorage.getItem("settingObj"));
  }

  ngOnInit() {

    //noinspection TypeScriptValidateTypes
    this._content.getContentDetails(this.slug).then((response)=> {
      let result = (response as any);
      if (result.success == true) {
        this.content = result.content;
        this.spinnerService.hide();
      }
    }).catch((error) => console.error(error));

    this.spinnerService.hide();
  }

  openSocialLink(settingObj,type)
  {
    if(type==1)
      window.open(settingObj.facebook_url, "_blank");
      if(type==2)
      window.open(settingObj.twitter_url, "_blank");
      if(type==3)
      window.open(settingObj.instagram_url, "_blank");
  }

  // ratingsCaller()
  // {
  //   $(".ratingsContWrapperOverlay").fadeIn(200);
  //   $("body").addClass("noScroll");
  //   $(".ratingsContWrapper").slideDown();

  //   $(".closeRevMod").click(function () {
  //     $(".ratingsContWrapperOverlay").fadeOut(200);
  //     $("body").removeClass("noScroll");
  //     $(".ratingsContWrapper").slideUp();
  //   });
  //   $(".ratingsContWrapperOverlay").click(function () {
  //     $(this).fadeOut(200);
  //     $("body").removeClass("noScroll");
  //     $(".ratingsContWrapper").slideUp();
  //   })
  // }

  ratingsCallerModal()
  {
    $(".ratingsContWrapperOverlay").fadeIn(200);
    $("body").addClass("noScroll");
    $(".ratingsContWrapper").slideDown();

    $(".closeRevMod").click(function () {
      $(".ratingsContWrapperOverlay").fadeOut(200);
      $("body").removeClass("noScroll");
      $(".ratingsContWrapper").slideUp();
    });
    $(".ratingsContWrapperOverlay").click(function () {
      $(this).fadeOut(200);
      $("body").removeClass("noScroll");
      $(".ratingsContWrapper").slideUp();
    })
  }

  ratingsCaller()
  {
    this.spinnerService.show();
    this.orderService.getRatingData().then((resJson)=> {
      let result = (resJson as any);
      if (result.success == true) {
        this.overallRating = result.overallRating;
        this.ratingData = result.outputRatingPercentage;
        this.spinnerService.hide();
        this.ratingsCallerModal();
        console.log(result);
      }
    }).catch((error) => console.error(error));
  }
}


