import { Component, OnInit,Inject,PLATFORM_ID } from '@angular/core';
import { LoginRegistrationService } from '../../services/login-registration.service';
import { CategoryService } from '../../services/category.service';
import { first, count,map, tap, debounceTime, distinctUntilChanged, switchMap, flatMap,finalize } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, NavigationEnd,Params ,ActivatedRoute} from '@angular/router';
import { CartServiceService } from '../../services/cart-service.service';
import { LocationService } from '../../services/location.service';
import { MessageService } from '../../services/message.service';
import {IMyDpOptions} from 'mydatepicker';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public loggedIn:boolean = false;
  public userToken:any;
  public categories:any;
  public categoriesMenu:any;
  public userId = null;
  navItems:any;


  public coupon_code:string = '';
  public couponPrice:any=0;
  public apply_coupon_code:any;
  public userWalletAmount:any;

  public cartData = [];
  public totalPrice:any;
  public finalPrice:any=0;
  public totalItems:any = 0;
  public shippingCost:any = 0;
  public search:any;
  public selectCurrentAddress = 'Search Location';
  public keyword = 'code';
  public data:any;
  public pinCodeCurrent:any;
  public errorText:boolean = false;
  public showCart:boolean = true;
  public profileImgPreview:any;
  public showPostalCode:any;
  public settingObj:any;
  public needAmountForFreeDelivery:any;
  public minCodAmount:any;
  pageStatus = 'otp';
  public generate_otp:any;
  public bulkOrderObj:any;
  public apiOtp:any;
  public updateDisabled:boolean=false;
  public newProfileName:string = '';
  deliveryChargeMsg:string = '';
  referralCode:string='';
  referralCodeWithMessage:string='';
  siteRegion:string;
  public regionId:any;

  public myDatePickerOptions: IMyDpOptions= {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    //disableDateRanges:[{begin: {year: 1971, month: 1, day: 1}, end: {year: this.paymentDateObj.getFullYear(), month: this.paymentDateObj.getMonth() + 1, day: this.paymentDateObj.getDate()}}],
    /*disableDays:[
     {year: 2019, month: 1, day: 24},
     {year: 2019, month: 1, day: 25}
     ],*/
    disableWeekends: false,
    //sunHighlight: false
   // disableWeekdays: ['su']
  };

  defaultLocation= {
    code: "110016",
    country_name: "India",
    country_code: "IND",
    state_title: "Delhi",
    zone_name: "Delhi (South)",
    cod: 0,
    title: "GREEN PARK MARKET",
    code_new: "110016 ( GREEN PARK MARKET ) "
  };
  defaultLocationTwo= {
    code: "700086",
    code_new: "700086",
    country_name: "India",
    country_code: "IND",
    state_title: "West Bengal",
    zone_name: "KOLKATA (S & N)",
    cod: 1,
    title: ""
  };
    public slots = [];
    public slotMsg:any;
    cookieValue:any;
    public groceryMinAmount= 599;
    public gloceryFlag;


    //if (orderNumber != '' || orderNumber!=undefined) {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public auth:LoginRegistrationService,
    private spinnerService:Ng4LoadingSpinnerService,
    private categoryService:CategoryService,
    private router:Router,
    private activeRouter:ActivatedRoute,
    private cart:CartServiceService,
    private location:LocationService,
    private msgService: MessageService,
    private cookieService: CookieService ) {

    this.search = {"category": "all", "productName": ""};

    router.events.subscribe(event => {

      if (event instanceof NavigationEnd ) {

        if(event.url === '/payment') {
          this.showCart = false;
        } else {
          this.showCart = true;
        }

        if(event.url == '/delivery-summary' || event.url == '/checkout-address' || event.url == '/payment' || event.url == '/order-placed' || event.url == '/' || event.url == '/home') {
          this.search = {"category": "all", "productName": ""};
        }

            ////////  New Eplement for slot modal
            if(event.url === '/' || event.url === '/home') {

              // this.cookieValue = this.cookieService.get('modalValue');
              // if(this.cookieValue)
              // {

              // }

              // $(".timeSlotOverlay").fadeIn(200);
              // $("body").addClass("noScroll");
              // $(".timeSlotWrapper").slideDown();

              // if($(".timeSlotWrapper").slideDown())
              // {
              //   this.cookieService.set( 'modalValue', 'true' );
              // }

              // $(".closeTimeSlot").click(function(){
              //   $(".timeSlotOverlay").fadeOut(200);
              //   $("body").removeClass("noScroll");
              //   $(".timeSlotWrapper").slideUp();
              // })
              // $(".timeSlotOverlay").click(function(){
              //   $(this).fadeOut(200);
              //   $("body").removeClass("noScroll");
              //   $(".timeSlotWrapper").slideUp();
              // })

              if((localStorage.getItem("postal_code")!=null))
              {
                console.log(this.showPostalCode);
                this.deliverySlots(this.showPostalCode);
              }
            }
            ////// End
            if( event.url == '/' || event.url == '/home') {
              if((localStorage.getItem("closeRegion")==null))
              {
               // this.regionCaller(); // 29.01.21
              }
            }
          }
        });

      this.showPostalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');

    if(localStorage.getItem('profilePicture')!=null) {
      this.profileImgPreview = localStorage.getItem('profilePicture');
    }

    if(this.loggedIn==true) {
      this.getLoginUserDetails();
    }

    /////// This Area for COD option open or not /////////////
    this.settingObj = JSON.parse(localStorage.getItem("settingObj"));
    if( this.settingObj)
    {
      if(this.minCodAmount>this.finalPrice)
      {
        if(this.settingObj!=null)
        this.settingObj.cod = 0;
       // this.needAmountForFreeDelivery = (this.minCodAmount - (this.totalPrice + this.shippingCost));
        this.needAmountForFreeDelivery = (this.minCodAmount - (this.totalPrice));
        //percentageNeedAmountForFreeDelivery
      }
      else
      {
        if(this.settingObj!=null)
          this.settingObj.cod = 1;
      }
    }
    //else
    //{
    //  this.settingObj.cod = 1;
    //}
    ////// This Area For Bulk Order //////////
    this.generate_otp={phone:''};
    this.bulkOrderObj={phone:'',otp:'',product_name:'',quantity:10,delivery_date:null};

    /// This Code update for urgent purpose, need to comment this code
    if (localStorage.getItem("postal_code") == '' || localStorage.getItem("postal_code")==undefined || localStorage.getItem("postal_code")==null ) {
      this.selectEvent(this.defaultLocation);
    }
        //// For Localstorage  ///////
    if(localStorage.getItem('region')==null) {
      localStorage.setItem('region','1');
      this.siteRegion = localStorage.getItem('region');
     // this.selectEvent(this.defaultLocation);
    }
    if(localStorage.getItem('region')!=null) {
      this.siteRegion = localStorage.getItem('region');
    }
    //// For cookie ///////
    // if(this.cookieService.get('region')==null ||  this.cookieService.get('region')=='') {
    //   this.cookieService.set('region', '1', null, null, null, null, null);
    //   //this.siteRegion = localStorage.getItem('region');
    //   this.siteRegionCookie = this.cookieService.get('region');
    //   this.selectEvent(this.defaultLocation);
    // }
    // if( this.cookieService.get('region')!=null ||  this.cookieService.get('region')!='') {
    //   //this.siteRegion = localStorage.getItem('region');
    //   this.siteRegionCookie = this.cookieService.get('region');
    // }
  }

  ngOnInit() {

    //this.givingRevFunction();


    this.getSettingData();
    if (this.auth.isAuthenticated()) {
      this.loggedIn = true;
      this.userId = this.auth.user();
      this.userToken = this.auth.getToken();
     // alert("here");
    }

    this.auth.watch().subscribe((value)=> {
      if (value == "false") {
        this.loggedIn = false;
        this.router.navigate(['/']);
      }
      else if (value == "true") {
        this.loggedIn = true;
        this.userId = this.auth.user();
      }

    });
    this.auth.watchProfilePicture().subscribe((value)=> {
      this.profileImgPreview  = localStorage.setItem('profilePicture',value);
      if(localStorage.getItem('profilePicture'))
      {
        this.profileImgPreview = localStorage.getItem('profilePicture');
      }
     // this.profileImgPreview = value;
    });

    if(localStorage.getItem('profilePicture')!=null)
    {
      this.profileImgPreview = localStorage.getItem('profilePicture');
    }

    this.auth.watchPostalCode().subscribe((value)=> {
      this.showPostalCode = value;
      this.location.getPinSearch(this.showPostalCode).then((response)=> {
        let result = (response as any);
        if (result.success == true) {
          let newArea = localStorage.getItem("selectCurrentAddress")
          this.selectCurrentAddress = (result.data[0].title!=null)?newArea:result.data[0].zone_name + ', ' + result.data[0].state_title;
        }
      }).catch((error) => console.error(error))

    });

    var token = '';

    if (this.userToken != null && this.userToken != 'null') {
      token = this.userToken;
    }
    else {
      token = localStorage.getItem("cartToken");
    }

    //alert(token);

    this.cart.cartAllProduct(token);
    this.cart.watch().subscribe((data)=> {
      this.cartData = data.cartContent;
      this.totalPrice = data.totalPrice;
      this.totalItems = data.totalProductItemInCart;
      this.shippingCost = data.shippingCost;
      this.couponPrice = data.coupon_price;
      this.finalPrice = data.finalPrice;
      this.apply_coupon_code = data.coupon_code;
      this.coupon_code = data.coupon_code;
      this.userWalletAmount = data.userWalletAmount;
      this.deliveryChargeMsg = data.deliveryChargeMsg;
      this.gloceryFlag = data.groceryFlag;

      if(this.loggedIn==true) {
        this.getLoginUserDetails();
      }

      /////// This Area for COD option open or not /////////////
      if(this.minCodAmount>this.finalPrice)
      {
        if(this.settingObj!=null)
          this.settingObj.cod = 0;
        //this.needAmountForFreeDelivery = (this.minCodAmount - (this.totalPrice + this.shippingCost));
        this.needAmountForFreeDelivery = (this.minCodAmount - (this.totalPrice));
      }
      //else
      //{
      //  this.settingObj.cod = 1;
      //}

      if(this.cartData.length>0)
      {
          this.regionId = this.cartData[0].region_id;
      }

    });
    console.log('kkkkk',this.cartData.length);


    this.getCategoryData();
    this.getCategoryMenuData();

    if(localStorage.getItem("postal_code")==undefined || localStorage.getItem("postal_code")=='' ) {
      /*$(".locationOverlay").fadeIn(200);
      $("body").addClass("noScroll");
      $(".selectLocation").slideDown();*/
    } else {
      //alert("here");
      this.selectCurrentAddress=localStorage.getItem("selectCurrentAddress");
      //console.log(this.selectCurrentAddress);
    }

    this.location.watch().subscribe((value)=> {
      this.selectCurrentAddress=localStorage.getItem("selectCurrentAddress");
    });

    // $("#timeSlotOpen").click(function(){
    //   $(".timeSlotOverlay").fadeIn(200);
    //   $("body").addClass("noScroll");
    //   $(".timeSlotWrapper").slideDown();
    // });

    // $(".timeSlotOverlay").fadeIn(200);
    // $("body").addClass("noScroll");
    // $(".timeSlotWrapper").slideDown();

    // $(".closeTimeSlot").click(function(){
    //   $(".timeSlotOverlay").fadeOut(200);
    //   $("body").removeClass("noScroll");
    //   $(".timeSlotWrapper").slideUp();
    // })
    // $(".timeSlotOverlay").click(function(){
    //   $(this).fadeOut(200);
    //   $("body").removeClass("noScroll");
    //   $(".timeSlotWrapper").slideUp();
    // })

    // $(".openDeliveryCaller").click(function(){
    //   alert("here")
    //  $(".DeliveryAvailableModalOverlay").fadeIn(200);
    //  $("body").addClass("noScroll");
    //  $(".DeliveryAvailableModal").slideDown();
    // });
    // $(".DeliveryAvail").click(function(){
    //   $(".DeliveryAvailableModalOverlay").fadeOut(200);
    //   $("body").removeClass("noScroll");
    //   $(".DeliveryAvailableModal").slideUp();
    // });
    // $(".DeliveryAvailableModalOverlay").click(function(){
    //   $(this).fadeOut(200);
    //   $("body").removeClass("noScroll");
    //   $(".DeliveryAvailableModal").slideUp();
    // })
  }


  logout() {
    this.spinnerService.show();
    this.auth.getLogout()
      .pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            this.auth.logout();
            this.spinnerService.hide();
            this.msgService.errMessage('Log out', 'You are logged out', 'Ok');
            localStorage.removeItem("slot_id");
            localStorage.removeItem("cartSelectedAddressId");
            localStorage.removeItem("cartSelectedPincode");
            localStorage.removeItem("orderNumber");
            localStorage.removeItem("oldOrderNumber");
            localStorage.removeItem("postal_code");
            localStorage.removeItem("token");
            localStorage.removeItem("new_delivery_date");
            localStorage.removeItem("userName");
            localStorage.removeItem("profilePicture");
            this.cart.cartAllProduct(localStorage.getItem("cartToken"));
            this.cart.watch().subscribe((data)=> {
              this.cartData = data.cartContent;
              this.totalPrice = data.totalPrice;
              this.totalItems = data.totalProductItemInCart;
              this.shippingCost = data.shippingCost;
            });
          } else if (response.success == false) {
            localStorage.removeItem("slot_id");
            localStorage.removeItem("cartSelectedAddressId");
            localStorage.removeItem("cartSelectedPincode");
            localStorage.removeItem("orderNumber");
            localStorage.removeItem("oldOrderNumber");
            localStorage.removeItem("postal_code");
            localStorage.removeItem("token");
            localStorage.removeItem("new_delivery_date");
            localStorage.removeItem("userName");
            localStorage.removeItem("profilePicture");
            this.cart.cartAllProduct(localStorage.getItem("cartToken"));
            this.cart.watch().subscribe((data)=> {
              this.cartData = data.cartContent;
              this.totalPrice = data.totalPrice;
              this.totalItems = data.totalProductItemInCart;
              this.shippingCost = data.shippingCost;
            });

          }
        },
        error => {
          console.log('Error ....');
        });
  }

  getCategoryMenuData() {
    this.spinnerService.show();
    //noinspection TypeScriptValidateTypes
    this.categoryService.getCategoryMenuData().then((response)=> {
      let result = (response as any);

      if (result.success == true) {
        this.categoriesMenu = result.data.categories;
        this.spinnerService.hide();
      }
    }).catch((error) => console.error(error));
  }

  removeCartItem(productId, productCuttingOptionId) {
    this.spinnerService.show();
    var result = confirm("Are you sure you want to remove product from cart?");
    if (result) {
      //Logic to delete the item
      this.cart.removeCart(productId, productCuttingOptionId).then((response)=> {
        let result = (response as any);
        this.cart.cartBroadCastTypeOne(result);
        this.msgService.removeCartMessage();
        this.spinnerService.hide();
      }).catch((error) => console.error(error));
    }
    else{
      this.spinnerService.hide();
    }
  }

  quantityUpdate(productId, productCuttingOptionId, quantity, type, index) {
    this.spinnerService.show();
    this.updateDisabled = true;
    

    if (type == 1) {
      quantity = parseInt(quantity) + 1;
    }
    if (type == 0) {
      quantity = parseInt(quantity) - 1;

      if(quantity<=0) {
        quantity = 1;
      }
    }

    this.cartData[index].quantity = quantity;

    if(type==3) {
      if ((isNaN(quantity) || quantity == "" )) {
        this.msgService.errMessage('Error', 'Please enter valid quantity', 'Ok');
        this.spinnerService.hide();
        quantity = 1;
        this.cartData[index].quantity = quantity;
      }

      if(quantity<0 || quantity==0) {
        quantity = 1;
        this.cartData[index].quantity = quantity;
      } else {
        this.cartData[index].quantity = parseInt(quantity);
      }
    }

    if(quantity>1000 || quantity==1001) {
      this.msgService.errMessage('Error', 'Maximum one thousand quantity to add the shopping cart.', 'Ok');
      quantity = 1000;
      this.cartData[index].quantity = quantity;
    }

    //noinspection TypeScriptValidateTypes
    this.cart.updateCartQuantity(productId, productCuttingOptionId, quantity).then((response)=> {
      let result = (response as any);
     // console.log(result)
    /////// This Area for COD option open or not /////////////
    this.settingObj = JSON.parse(localStorage.getItem("settingObj"));
    if( this.settingObj)
    {
      if(this.minCodAmount>result.finalPrice)
      {
        if(this.settingObj!=null)
          this.settingObj.cod = 0;
       // this.needAmountForFreeDelivery = (this.minCodAmount - (this.totalPrice + this.shippingCost));
        this.needAmountForFreeDelivery = (this.minCodAmount - (result.totalPrice));
        //percentageNeedAmountForFreeDelivery
      }
      else
      {
        if(this.settingObj!=null)
          this.settingObj.cod = 1;
      }
    }
      this.cart.cartBroadCastTypeOne(result);
      this.updateDisabled = false;
      //this.spinnerService.hide();
    }).catch((error) => console.error(error));
   // this.spinnerService.hide();
  }

  getCategoryData() {
    this.spinnerService.show();
    //noinspection TypeScriptValidateTypes
    this.categoryService.getCategoryData().then((response)=> {
      let result = (response as any);
      if (result.success == true) {
        this.categories = result.categories;
        this.spinnerService.hide();
      }
    }).catch((error) => console.error(error));
  }


  getLocationData() {
    this.spinnerService.show();
    //noinspection TypeScriptValidateTypes
    this.location.getPosition().then((response)=> {
      let result = (response as any);
      this.getAddressData(result.lat, result.lng);
    }).catch((error) => console.error(error));
  }

  getAddressData(lat, long) {
    //noinspection TypeScriptValidateTypes
    this.location.getAddressData(lat, long).then((response)=> {
      let result = (response as any);

      if(result.results[0].components.postcode!=undefined) {
        this.spinnerService.show();
        this.location.getPinSearch(result.results[0].components.postcode).then((response)=> {
          let resultNew = (response as any);
          if (resultNew.success == true) {
            localStorage.setItem("postal_code", resultNew.data[0].code);
            localStorage.setItem("selectCurrentAddress", resultNew.data[0].zone_name + ', ' + resultNew.data[0].state_title);
            var domain = "freshfishbasket.in";
            if (isPlatformBrowser(this.platformId)) {
              domain = window.location.hostname;
              if(domain == "www.freshfishbasket.in")
              {
                domain = "freshfishbasket.in";
              }
            }
            this.cookieService.set("postal_code",resultNew.data[0].code,(new Date().getTime()+3600*24*365),"/",domain);
            this.cookieService.set("postal_code",resultNew.data[0].zone_name + ', ' + resultNew.data[0].state_title,(new Date().getTime()+3600*24*365),"/",domain);



            this.pinCodeCurrent = result.results[0].components.postcode;
            this.errorText = false;
            this.spinnerService.hide();
            if(this.router.url != '/delivery-summary' && this.router.url != '/checkout-address' && this.router.url != '/payment' && this.router.url != '/order-placed' &&  this.router.url != '/account') {
              location.reload();
            }
          } else if (resultNew.success == false) {
            this.errorText = true;
            this.data = [];
            this.pinCodeCurrent = result.results[0].components.postcode;
            this.spinnerService.hide();
          }
          else
          {
            this.errorText = true;
            this.data = [];
            this.spinnerService.hide();
          }
        }).catch((error) => console.error(error))
      }
      else
      {
        this.errorText = true;
        this.data = [];
      }
    }).catch((error) => console.error(error));
  }

  fetchCurrentLocation() {
    this.getLocationData();
  }


  selectEvent(item) {

    if(this.router.url === '/delivery-summary' || this.router.url === '/checkout-address' || this.router.url === '/payment') {
      this.msgService.errMessage('Error', 'You have not permission to set postal code', 'Ok');
      return false;
    }
    this.spinnerService.show();
    this.errorText = false;
    localStorage.setItem("postal_code", item.code);
    var domain = "freshfishbasket.in";
    if (isPlatformBrowser(this.platformId)) {
      domain = window.location.hostname;
      if(domain == "www.freshfishbasket.in")
      {
        domain = "freshfishbasket.in";
      }
    }
    this.cookieService.set("postal_code",item.code,(new Date().getTime()+3600*24*365),"/",domain);
    //// new code 15.05.20
    let zone_area = '';
    if(item.title)
    {
      zone_area = item.title;
    }
    else
      zone_area = item.zone_name;

    localStorage.setItem("selectCurrentAddress", zone_area + ', ' + item.state_title);
    this.selectCurrentAddress = item.zone_name + ', ' + item.state_title;
    if (isPlatformBrowser(this.platformId)) {
      $(".locationOverlay").fadeOut(200);
      $("body").removeClass("noScroll");
      $(".selectLocation").slideUp();
    }
    var locationData = {postal_code: item.code, address: this.selectCurrentAddress};
    this.location.setLocation(locationData);
    //// Cart data shipping charge update //////

    var token = '';
    if (this.userToken != null && this.userToken != 'null') {
      token = this.userToken;
    }
    else {
      token = localStorage.getItem("cartToken");
    }
    this.cart.cartAllProduct(token);
    this.cart.watch().subscribe((data)=> {
      this.cartData = data.cartContent;
      this.totalPrice = data.totalPrice;
      //this.totalItems = this.cartData.length;
      this.totalItems = data.totalProductItemInCart;
      //this.totalItems = data.totalItemQuantity;
      this.shippingCost = data.shippingCost;
      this.couponPrice = data.coupon_price;
      this.finalPrice = data.finalPrice;
      this.apply_coupon_code = data.coupon_code;
      this.coupon_code = data.coupon_code;
      this.userWalletAmount = data.userWalletAmount;

      // if(this.router.url != '/delivery-summary' && this.router.url != '/checkout-address' && this.router.url != '/payment' && this.router.url != '/order-placed' &&  this.router.url != '/account') {
      //   if (isPlatformBrowser(this.platformId)) {
      //     location.reload();
      //   }
        if(this.router.url != '/delivery-summary' && this.router.url != '/checkout-address' && this.router.url != '/payment' && this.router.url != '/order-placed') {
          if (isPlatformBrowser(this.platformId)) {
            location.reload();
          }
      }

      if(this.minCodAmount>this.finalPrice) {
        if(this.settingObj!=null)
          this.settingObj.cod = 0;
        this.needAmountForFreeDelivery = (this.minCodAmount - (this.totalPrice));
      }
      else
      {
        if(this.settingObj!=null)
          this.settingObj.cod = 1;
      }

    });
  }

  onChangeSearch(val:string) {
    //alert(val);
    if(val!='') {
      this.spinnerService.show();
      this.location.getPinSearch(val).then((response)=> {
        let result = (response as any);
        if (result.success == true) {
          // Modified for onblur postal code select
          if(result.data.length==1 && val.length==6)
          {
            this.selectEvent(result.data[0]);
          }
          /// End
          this.data = result.data;
          this.errorText = false;
          this.spinnerService.hide();
        } else if (result.success == false) {
          this.errorText = true;
          this.data = [];
          this.spinnerService.hide();
        } else {
          this.errorText = true;
          this.data = [];
          this.spinnerService.hide();
        }
      }).catch((error) => console.error(error));
    }
    else
    {
      this.data = [];
      this.errorText = false;
    }
  }
  closeLocationModal()
  {
    this.data = [];
    this.pinCodeCurrent = '';
    this.errorText = false;
  }

  onFocused(e) {
    // do something when input is focused
  }


  applyCouponCode(type) {

    var coupon = '';

    switch (type) {
      case 0:
        coupon = 'NO';
        this.coupon_code='';
        break;
      case 1:
        if(this.coupon_code=='' || this.coupon_code==undefined) {
          coupon = 'NO';
        } else {
          coupon = this.coupon_code;
        }
        break;
    }

    if (this.loggedIn) {
      this.spinnerService.show();
      this.cart.addCouponInCart(coupon, this.userId).then((response)=> {
        let result = (response as any);
        if (result.data.success == true) {
          if(result.data.coupon_code=='' && type!=0)
          {
            this.msgService.errMessage('Error', 'Please apply valid coupon code', 'Ok');
          }
          this.cart.cartBroadCastTypeOne(result.data);
          this.spinnerService.hide();
        }
      }).catch((error) => console.error(error));
    } else {
      this.spinnerService.show();
      this.cart.addCouponInCart(coupon, null).then((response)=> {
        let result = (response as any);
        if (result.data.success == true) {
          if(result.data.coupon_code=='' && type!=0)
          {
            this.msgService.errMessage('Error', 'Please apply valid coupon code', 'Ok');
          }
          this.cart.cartBroadCastTypeTwo(result);
          this.spinnerService.hide();
        }
      }).catch((error) => console.error(error));
    }
  }

  submitSearchForm() {
    if(this.search.category!='' && this.search.productName!='') {
      let productName = btoa(unescape(encodeURIComponent(this.search.productName)));
      this.router.navigateByUrl('/products/'+this.search.category+'/'+productName);
    } else if(this.search.category!='') {
      this.router.navigateByUrl('/products/'+this.search.category);
    }
  }

  getLoginUserDetails(){
    this.spinnerService.show();
    this.auth.getLoginUserDetails().then((resJson)=> {
      let result = (resJson as any);
      if (result.success == true) {
         this.profileImgPreview = result.data.image;
         this.newProfileName = result.data.name;
         this.referralCode = result.data.referral_code;
         this.referralCodeWithMessage = "Get a discount for first-time order by using code - "+this.referralCode+" on registration. Place an order from https://bit.ly/3bRC50n or from our mobile App (Android - https://rb.gy/3hqtan or IOS - https://rb.gy/1bml3e)";
        console.log(result);
        this.spinnerService.hide();
      }
    }).catch((error) => console.error(error));
  }

  getSettingData() {
    this.cart.getSettingData().then((resJson)=> {
      let result = (resJson as any);
      if (result.success == "true") {
        localStorage.setItem('settingObj',JSON.stringify(result.setting));
         this.settingObj = result.setting;
        this.minCodAmount = this.settingObj.cod_min_amount;
        this.spinnerService.hide();
      }
    }).catch((error) => console.error(error));
  }
  searchProductNav(slug) {
    this.search.category = slug;
    this.router.navigateByUrl('/products/'+slug);
  }

  openBulkOrder() {
   // alert("here");
  }

  dismissBulkOrder() {
    this.generate_otp={phone:''};
    this.bulkOrderObj={phone:'',product_name:'',quantity:10,delivery_date:null};
    this.pageStatus="otp";
  }

  submitGenerateOtpForm() {
    if ((isNaN(this.generate_otp.phone) || this.generate_otp.phone == "" )) {
      this.msgService.errMessage('Error', 'Please enter your phone number', 'ok');

      return false;
    }

    if (this.generate_otp.phone.length < 10) {
      this.msgService.errMessage('Error', 'Please enter correct phone number', 'ok');
      return false;
    }
    this.spinnerService.show();
    this.auth.generateBulkOtp(this.generate_otp).pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            this.spinnerService.hide();
            this.msgService.successMessage('OTP', 'OTP has been sent successfully. Please check your phone.', 'ok');
            this.bulkOrderObj.phone=this.generate_otp.phone;
            this.apiOtp = response.otp;
            this.pageStatus = "verifyOtp";
          } else if (response.success == false) {
            this.spinnerService.hide();
            this.msgService.errMessage('Error', 'Please enter your valid phone.', 'ok');
            return false;
          }
        },
        error => {
          console.log('Error ....');
        });
  }

  submitOtp() {
    if ((isNaN(this.bulkOrderObj.phone) || this.bulkOrderObj.phone == "" )) {
      this.msgService.errMessage('Error', 'Please enter your phone number', 'ok');
      return false;
    }

    if (this.bulkOrderObj.phone.length < 10) {

      this.msgService.errMessage('Error', 'Please enter correct phone number', 'ok');

      return false;
    }
    if ((isNaN(this.bulkOrderObj.otp) || this.bulkOrderObj.otp == "" )) {

      this.msgService.errMessage('Error', 'Please enter your otp', 'ok');

      return false;
    }

    if(this.bulkOrderObj.otp != this.apiOtp)
    {
      this.msgService.errMessage('Error', 'Please enter valid otp', 'ok');

      return false;
    }
    else
    {
      this.pageStatus = 'submitBulkOrder';
    }

  }

  submitBulkOrder() {
    if (this.bulkOrderObj.product_name == "" ) {
      this.msgService.errMessage('Error', 'Please enter product name', 'ok');

      return false;
    }

    if (this.bulkOrderObj.quantity == "" || isNaN(this.bulkOrderObj.quantity)  ) {
      this.msgService.errMessage('Error', 'Please enter valid quantity', 'ok');

      return false;
    }

    if (this.bulkOrderObj.quantity<10  ) {
      this.msgService.errMessage('Error', 'Quantity not accept less than 10 KG for Bulk Order', 'ok');

      return false;
    }

    if (this.bulkOrderObj.quantity>100  ) {
      this.msgService.errMessage('Error', 'Quantity not accept grater than 100 KG for Bulk Order', 'ok');

      return false;
    }

    if (this.bulkOrderObj.delivery_date == null ) {
      this.msgService.errMessage('Error', 'Please enter Delivery Date', 'ok');

      return false;
    }

    this.spinnerService.show();
    this.auth.bulkOrderSave(this.bulkOrderObj).pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            this.spinnerService.hide();
            this.dismissBulkOrder();
            if (isPlatformBrowser(this.platformId)) {
              $(".bulkOrderOverlay").fadeOut();
              $(".bulkOrderModal").removeClass("boShown");
              $("body").removeClass("noScroll");
            }
            this.msgService.successMessage('Success', 'Thank You for submitting bulk order. One of our representatives will get in touch with you shortly..', 'ok');
            this.router.navigateByUrl('/');
          } else if (response.success == false) {
            this.spinnerService.hide();

            this.msgService.errMessage('Error', response.message, 'ok');

            return false;
          }
        },
        error => {
          console.log('Error ....');
        });

  }

  gotoPhoneNum() {
    this.generate_otp={phone:''};
    this.bulkOrderObj={phone:'',product_name:'',quantity:10,delivery_date:null};
    this.pageStatus="otp";
  }

    deliverySlots(code,date='') {

      this.spinnerService.show();

      this.location.getDeliverySlot(code,date).then((resJsn)=> {

        let response = (resJsn as any);

        if (response.success == true) {
            this.slotMsg = response.msg;
          if(response.data && response.data.length > 0){
            this.slots = response.data;
            console.log(this.slots)
            // this.deliverySlotId = slotValue.id;
            // this.slotText = slotValue.text + " (" +slotValue.day_name+")";
            // localStorage.setItem("slot_id", this.deliverySlotId);

          } else {

            this.slots = [];
          }
        }
        else if (response.success == false) {

          this.slots = [];

          this.msgService.errMessage('Error', 'Pin code not available. Please other one....', 'Ok');
        }
      }).catch((error) => console.error(error));
  }

  // open searchModal on mobile screen
  openSearchBox(){
    // alert('hi');
    if (isPlatformBrowser(this.platformId)) {
      $(".navSearchSm").slideToggle();

    }
    // $(".mobsearchCaller").click(function(){
    //   $(".navSearchSm").slideToggle();
    // })
  }

  openRefBox(){
    $('.shareReferHolder').addClass("showRef");
  };
  closeRefBox(){
    $('.shareReferHolder').removeClass("showRef");
  }
  copyReferral(val: string)
  {
    // inputElement.select();
    // console.log(inputElement)
    // document.execCommand('copy');
    // inputElement.setSelectionRange(0, 0);
    // this.msgService.successMessage('Success', 'Referral code copied', 'ok');
    // this.closeRefBox();
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.msgService.successMessage('Success', 'Referral code copied', 'ok');
    this.closeRefBox();
  }

  regionCaller()
  {
    $(".reviewContWrapperOverlay").fadeIn(200);
    $("body").addClass("noScroll");
    $(".reviewContWrapper").slideDown();

    $(".closeRevMod").click(function () {
      $(".reviewContWrapperOverlay").fadeOut(200);
      $("body").removeClass("noScroll");
      $(".reviewContWrapper").slideUp();
    });
    $(".reviewContWrapperOverlay").click(function () {
      $(this).fadeOut(200);
      $("body").removeClass("noScroll");
      $(".reviewContWrapper").slideUp();
    })
  }

  // givingRevFunction(){
  //   $('.starSpan').mouseover(function(){
  //     $(this).addClass('active');
  //     $(this).prevAll().addClass('active');
  //     $(this).nextAll().removeClass('active');
  //   });
  //   $('.starSpan').mouseout(function(){
  //     $(this).nextAll().removeClass('active');
  //     $(this).prevAll().removeClass('active');
  //     $(this).removeClass('active');
  //   });
  // }
  
  changeRegion(regionId)
  {
    localStorage.setItem("region", regionId);
    if(regionId==1)
    {
      this.selectEvent(this.defaultLocation);
      localStorage.setItem("closeRegion", '1');
      //location.reload();

    }
    if(regionId==2)
    {
      this.selectEvent(this.defaultLocationTwo);
      localStorage.setItem("closeRegion", '1');
      //location.reload();
    }
  }
  
  checkOutAddress()
  {
    // this.router.navigate(['/checkout-address'])
    // .then(() => {
    //   window.location.reload();
    // });
    this.router.navigate(['/checkout-address']);
  }
}


