/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ng4-loading-spinner/ng4-loading-spinner.ngfactory";
import * as i3 from "ng4-loading-spinner";
import * as i4 from "./core/header/header.component.ngfactory";
import * as i5 from "./core/header/header.component";
import * as i6 from "./services/login-registration.service";
import * as i7 from "./services/category.service";
import * as i8 from "@angular/router";
import * as i9 from "./services/cart-service.service";
import * as i10 from "./services/location.service";
import * as i11 from "./services/message.service";
import * as i12 from "ngx-cookie-service";
import * as i13 from "./core/footer/footer.component.ngfactory";
import * as i14 from "./core/footer/footer.component";
import * as i15 from "./services/content.service";
import * as i16 from "./services/order.service";
import * as i17 from "./app.component";
import * as i18 from "@ng-toolkit/universal";
import * as i19 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng4-loading-spinner", [], null, null, null, i2.View_Ng4LoadingSpinnerComponent_0, i2.RenderType_Ng4LoadingSpinnerComponent)), i1.ɵdid(1, 180224, null, 0, i3.Ng4LoadingSpinnerComponent, [i3.Ng4LoadingSpinnerService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.HeaderComponent, [i1.PLATFORM_ID, i6.LoginRegistrationService, i3.Ng4LoadingSpinnerService, i7.CategoryService, i8.Router, i8.ActivatedRoute, i9.CartServiceService, i10.LocationService, i11.MessageService, i12.CookieService], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-footer", [], null, null, null, i13.View_FooterComponent_0, i13.RenderType_FooterComponent)), i1.ɵdid(7, 114688, null, 0, i14.FooterComponent, [i3.Ng4LoadingSpinnerService, i15.ContentService, i8.ActivatedRoute, i16.OrderService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i17.AppComponent, [i1.PLATFORM_ID, i18.WINDOW, i8.Router, i19.Title, i19.Meta, i9.CartServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
