import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartServiceService {

  cartChange:Subject<any> = new Subject<any>();
  protected baseURL = environment.baseURL;

  constructor(private http:HttpClient) {
  }


  watch():Observable<any> {
    return this.cartChange.asObservable();
  }

  cartBroadCastTypeOne(data) {
    this.cartChange.next(data);
  }

  cartBroadCastTypeTwo(data) {
    this.cartChange.next(data.data);
    localStorage.setItem("cartToken", data.token)
  }


  addCart(productId, productCuttingOptionId, userId,comment=null) {

    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
    var comment = ((comment!=null || comment!='')?comment:'');
   // alert(postalCode);
    //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
    if (productId) {
      var that = this;
      if (userId == null || userId == undefined) {
        console.log("not_login")
        var url = this.baseURL + "add-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("cartToken")+'&postal_code='+postalCode+'&comment='+comment+'&version='+Math.random();

        if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
        {
          url = url+'&region_id='+localStorage.getItem("region");
        }

        //alert(url);
        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      } else {
        console.log("login");
        var that = this;
        var url = this.baseURL + "add-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("token")+'&postal_code='+postalCode+'&comment='+comment+'&version='+Math.random();

        if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
        {
          url = url+'&region_id='+localStorage.getItem("region");
        }

        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      }
    }
  }

  addNoti(productId, productCuttingOptionId, userId,comment=null) {

    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
    var comment = ((comment!=null || comment!='')?comment:'');
   // alert(postalCode);
    //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
    if (productId) {
      var that = this;
      if (userId == null || userId == undefined) {
        console.log("not_login")
        var url = this.baseURL + "add-notification?product_id=" + productId +'&token=' + localStorage.getItem("cartToken")+'&postal_code='+postalCode;

        //alert(url);
        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      } else {
        console.log("login");
        var that = this;
        var url = this.baseURL + "add-notification?product_id=" + productId + '&token=' + localStorage.getItem("token")+'&postal_code='+postalCode;
        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      }
    }
  }

  productCheckInStock(productId, productCuttingOptionId)
  {
    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
    if (productId) {
      var that = this;
        var that = this;
        var url = this.baseURL + "check-in-stock?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId +'&postal_code='+postalCode;
        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      }
  }
  addCouponInCart(couponCode, userId) {
    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
    //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;
    if (couponCode) {
      var that = this;
      if (userId == null) {
        var url = this.baseURL + "add-cart?coupon_code=" + couponCode +'&token=' + localStorage.getItem("cartToken")+'&postal_code='+postalCode;

        if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
        {
          url = url+'&region_id='+localStorage.getItem("region");
        }

        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      }
      else {
        var that = this;
        var url = this.baseURL + "add-cart?coupon_code=" + couponCode + '&token=' + localStorage.getItem("token")+'&postal_code='+postalCode;

        if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
        {
          url = url+'&region_id='+localStorage.getItem("region");
        }

        let promise = new Promise((resolve, reject) => {
          this.http.get(url)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
            )
        });
        return promise;
      }
    }
  }

  removeCart(productId, productCuttingOptionId) {
    //alert("herennnn");
    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
    //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;

    if (localStorage.getItem("cartToken") != '' && localStorage.getItem("cartToken") != 'null' && localStorage.getItem("cartToken") != null) {
      var url = this.baseURL + "remove-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("cartToken")+'&postal_code='+postalCode+'&version='+Math.random();

      if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
      {
        url = url+'&region_id='+localStorage.getItem("region");
      }

      let promise = new Promise((resolve, reject) => {
        this.http.get(url)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          )
      });
      return promise;
    }
    else {
      var url = this.baseURL + "remove-cart?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&token=' + localStorage.getItem("token")+'&postal_code='+postalCode+'&version='+Math.random();

      if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
      {
        url = url+'&region_id='+localStorage.getItem("region");
      }

      let promise = new Promise((resolve, reject) => {
        this.http.get(url)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          )
      });
      return promise;
    }
  }

  updateCartQuantity(productId, productCuttingOptionId, quantity) {
    var cartSelectedAddressId = '';
    if (localStorage.getItem("cartSelectedAddressId") != undefined) {
      cartSelectedAddressId = localStorage.getItem("cartSelectedAddressId");
    }

    var token = null;
    if (localStorage.getItem("cartToken") != '' && localStorage.getItem("cartToken") != 'null' && localStorage.getItem("cartToken") != null) {
      token = localStorage.getItem("cartToken");
    }
    else {
      token = localStorage.getItem("token");
    }

    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');
    //var url = this.baseURL + "cart-detail?token=" + userToken+'&postal_code='+postalCode;


    var url = this.baseURL + "update-quantity?product_id=" + productId + '&product_cutting_option_id=' + productCuttingOptionId + '&quantity=' + quantity + "&token=" + token+'&postal_code='+postalCode+'&version='+Math.random();

    if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
    {
      url = url+'&region_id='+localStorage.getItem("region");
    }

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  /*cartAllProduct(newUserToken) {
    var that = this;
    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');

    var callUrl = this.baseURL + "cart-detail?token=" + newUserToken+'&postal_code='+postalCode;

    return this.http.get<any>(callUrl);
  }*/

  cartAllProduct(newUserToken) {
    var that = this;
    var postalCode = ((localStorage.getItem("postal_code")!=null)?localStorage.getItem("postal_code"):'');

    var url = this.baseURL + "cart-detail?token=" + newUserToken+'&postal_code='+postalCode;

    if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
    {
      url = url+'&region_id='+localStorage.getItem("region");
    }

    //return this.http.get<any>(callUrl);

      /*.pipe(map((response:any) => {
        return response;
      }));*/
      /*.subscribe(
        data => {
          console.log("Success response");
          this.cartChange.next(data);
        },
        error => {
          console.log('Error: Cart items is empty ....');
        }
      );*/

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            console.log("Success response");
            resolve(res);
            that.cartChange.next(res);
          },
          msg => { // Error
            console.log("Error response");
            reject(msg);
          }
        )
    });
    console.log('Return promise');
    return promise;
  }

  cartSummary(userToken, order_customer_address_id) {
    var that = this;
    var url = this.baseURL + "cart-summary?token=" + userToken + "?order_customer_address_id="+ order_customer_address_id;
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
            that.cartChange.next(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
  getSettingData() {
    var that = this;
    var url = this.baseURL + "setting";
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
}
