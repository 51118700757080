<div class="loginHolder">
  <div class="loginTabHolder">
    <a href="javascript:;" *ngIf="showLoginArea == true" [ngClass]="pageStatus == 'login' ? 'active' : ''"
       (click)="gotoLogin()">LOGIN</a>
    <a href="javascript:;" *ngIf="showRegisterArea == true" [ngClass]="pageStatus == 'register' ? 'active' : ''"
       (click)="gotoRegister()">REGISTER</a>
    <a href="javascript:;" *ngIf="showOTPArea == true" [ngClass]="pageStatus == 'otp' ? 'active' : ''"
       (click)="gotoOTP()">Login with OTP</a>
  </div>
  <div class="loginTabContent">
    <div class="loginTabInner" *ngIf="pageStatus == 'login'">
      <form (ngSubmit)="submitLoginForm()" id="loginForm">
        <div class="customForm">
          <img src="assets/images/icons/profile.svg"/>
          <input type="text" placeholder="Enter your email or phone number" id="login_email" name="email"
                 [(ngModel)]="login.email" required/>
        </div>
        <div class="customForm">
          <img src="assets/images/icons/padlock.svg"/>
          <input type="password" placeholder="Enter your password" id="login_password" name="password"
                 [(ngModel)]="login.password" required/>
        </div>
        <button type="submit" class="btn btn-block btn-primary btn-lg">LOGIN</button>
      </form>
      <div class="orDivider">
        - Or Login With -
      </div>
      <div class="row socialLogin">
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-success btn-lg"><img
            src="assets/images/icons/mobile-phone.svg"/>OTP LOGIN
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-primary btn-lg fb-bg"><img
            src="assets/images/icons/facebook-logo.svg"/>FACEBOOK
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-success btn-lg tw-bg"><img
            src="assets/images/icons/twitter.svg"/>TWITTER
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-success btn-lg g-bg"><img
            src="assets/images/icons/google.svg"/>GOOGLE
          </button>
        </div>
      </div>
    </div>
    <div class="loginTabInner" *ngIf="pageStatus == 'register'">
      <form (ngSubmit)="submitRegisterForm()" id="registerForm">
        <div class="customForm">
          <img src="assets/images/icons/profile.svg"/>
          <input type="text" placeholder="Enter your full name" id="registration_name" name="name"
                 [(ngModel)]="register.name" required/>
        </div>
        <div class="customForm">
          <img src="assets/images/icons/profile.svg"/>
          <input type="text" placeholder="Enter your email" id="registration_email" name="email"
                 [(ngModel)]="register.email" required/>
        </div>
        <div class="customForm">
          <img src="assets/images/icons/profile.svg"/>
          <input type="text" placeholder="Enter your phone number" id="registration_phone" name="phone"
                 [(ngModel)]="register.phone" required/>
        </div>
        <div class="customForm">
          <img src="assets/images/icons/padlock.svg"/>
          <input type="password" placeholder="Enter your password" id="register_password" name="password"
                 [(ngModel)]="register.password" required/>
        </div>
        <div class="customForm">
          <img src="assets/images/icons/padlock.svg"/>
          <input type="password" placeholder="Retype your password" id="register_confirm_password"
                 name="confirm_password" [(ngModel)]="register.confirm_password" required/>
        </div>
        <button class="btn btn-block btn-primary btn-lg" type="submit">Register</button>
      </form>
      <div class="orDivider">
        - Or Login With -
      </div>

      <div class="row socialLogin">
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-success btn-lg"><img
            src="assets/images/icons/mobile-phone.svg"/>OTP LOGIN
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-primary btn-lg fb-bg"><img
            src="assets/images/icons/facebook-logo.svg"/>FACEBOOK
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-success btn-lg tw-bg"><img
            src="assets/images/icons/twitter.svg"/>TWITTER
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-block btn-success btn-lg g-bg"><img
            src="assets/images/icons/google.svg"/>GOOGLE
          </button>
        </div>
      </div>
    </div>
    <div class="loginTabInner" *ngIf="pageStatus == 'otp'">

        <div class="otpStep1">
          <form (ngSubmit)="submitGenerateOtpForm()" id="generateOtpForm">
            <div class="customForm">
              <img src="assets/images/icons/smartphone.svg"/>
              <input type="text" placeholder="Enter your phone number" id="generate_otp_phone" name="phone" maxlength="10"
                     [(ngModel)]="generate_otp.phone" required/>
            </div>
            <button type="submit" class="btn btn-block btn-primary">PROCEED</button><!--(click)="gotoEnterOTP()"-->
          </form>
        </div>

        <div class="otpStep2">
          <form (ngSubmit)="submitOtpLoginForm()" id="submitOtpLoginForm">

            <div class="customForm">
              <img src="assets/images/icons/smartphone.svg"/>
              <input type="text" placeholder="Enter your phone number" id="otp_login_phone" name="phone"
                      [(ngModel)]="otp_login.phone" readonly required/>
            </div>
            <div class="customForm">
              <img src="assets/images/icons/padlock.svg"/>
              <input type="password" placeholder="Enter OTP" id="otp_login_otp" name="otp"
                      [(ngModel)]="otp_login.otp" required/>
            </div>



            <!--<div class="customForm">-->
              <!--<img src="assets/images/icons/profile.svg"/>-->
              <!--<input type="text" placeholder="Enter your name" required/>-->
            <!--</div>-->
            <ng-container *ngIf="userObject && userObject?.email==''">
              <div class="customForm">
                <img src="assets/images/icons/profile.svg">
                <input placeholder="Enter your name" type="text" id="name" name="name" [(ngModel)]="otp_login.name" required>
              </div>

              <div class="customForm">
                <img src="assets/images/icons/envelope-of-white-paper.svg">
                <input placeholder="Enter email" type="email"  id="email" name="email" [(ngModel)]="otp_login.email" required>
              </div>

              <div class="customForm" *ngIf="settingObj.referral==1">
                <img src="assets/images/icons/refer.svg"/>
                <input type="text" placeholder="Referral code name" id="used_referral_code" name="used_referral_code" [(ngModel)]="otp_login.used_referral_code" />
              </div>
            </ng-container>


            <button type="submit" class="btn btn-block btn-primary">LOGIN</button>

            <div class="gap10"></div>
            <div class="otpMoreOpt clearfix">
              <div class="float-left">
                <a href="javascript:;" (click)="gotoPhoneNum()" class="termCondLink">CHANGE PHONE NUMBER</a>
              </div>
              <div class="float-right">
                <a href="javascript:;" (click)="submitGenerateOtpForm()" *ngIf="timeLeft==0" class="termCondLink">RESEND OTP</a>
                <span class="otpCouter" *ngIf="timeLeft>0">{{timeLeft}} Seconds Left....</span>
              </div>
              <div class="clearfix"></div>
              <!-- <div class="float-left">
                <input [disabled]="isDisabledTerms" checked="checked" type="checkbox" id="Checkbox0" name="cCheckbox0" (change)="checkSelected(checkBox[0].label)">By signing in you are agreed to our <a href="javascript:;" (click)="openTerms()">Terms and Conditions</a>
              </div> -->
              <label class="container">By signing in you are agreed to our <a class="termCondLink" href="javascript:;" (click)="openTerms()">Terms and Conditions</a>
                <input type="checkbox" checked="checked" [disabled]="isDisabledTerms" id="Checkbox0" name="cCheckbox0" (change)="checkSelected(checkBox[0].label)">
                <span class="checkmark"></span>
              </label>
            </div>

          </form>
        </div>

        <!-- <div class="otpStep3">

            <div class="customForm">
              <img src="assets/images/icons/profile.svg"/>
              <input type="text" placeholder="Enter your name" required/>
            </div>
            <div class="customForm">
              <img src="assets/images/icons/envelope-of-white-paper.svg"/>
              <input type="text" placeholder="Enter email" required/>
            </div>
            <button type="submit" class="btn btn-block btn-primary">SUBMIT TO PROCEED</button>

        </div> -->

    </div>
  </div>
</div>
