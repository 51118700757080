///<reference path="../../../node_modules/sweetalert2/sweetalert2.d.ts"/>
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private head: string = "";
  private text: string = "";
  private btnText: string = "";

  constructor() { }

  errMessage(head, text, btnText) {
    this.head    = head;
    this.text    = text;
    this.btnText    = btnText;

    Swal.fire({title: this.head, text: this.text, type: 'error', confirmButtonText: this.btnText, confirmButtonColor: '#CC0000', timer: 3000});
  }

  removeCartMessage(){
   // Swal.fire({title: 'Error', text: 'Item removed successfully', type: 'warning', confirmButtonText: 'Ok', confirmButtonColor: '#CC0000', timer: 3000});
    Swal.fire({title: 'Success', text: 'Item removed successfully', type: 'success', confirmButtonText: 'Ok', confirmButtonColor: '#2fbf40', timer: 3000});
  }

  successMessage(head, text, btnText) {
    this.head    = head;
    this.text    = text;
    this.btnText    = btnText;

    Swal.fire({title: this.head, text: this.text, type: 'success', confirmButtonText: this.btnText, confirmButtonColor: '#2fbf40', timer: 3000});
  }

  addToCartMessage() {
    Swal.fire({title: 'Success', text: 'Added to your cart successfully', type: 'success', confirmButtonText: 'Ok', confirmButtonColor: '#2fbf40', timer: 3000});
  }

  addNotificationMessage(msg) {
    Swal.fire({title: 'Success', text: msg, type: 'success', confirmButtonText: 'Ok', confirmButtonColor: '#2fbf40', timer: 3000});
  }

  successMessage1(text) {
    Swal.fire({title: 'Are you sure?', text: 'You will not be able to recover this imaginary file!', type: 'warning', showCancelButton: true, confirmButtonText: 'Yes, delete it!', cancelButtonText: 'No, keep it', timer: 3000}).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    });
  }

  cancelOrderMessage() {
    Swal.fire({title: 'Are you sure?', text: 'Are you sure want to cancel this order?', type: 'warning', showCancelButton: true, confirmButtonText: 'Yes, Cancel it!', cancelButtonText: 'No, keep it', timer: 30000}).then((result) => {
      if (result.value) {
        return result.value;
      }
    });
  }

}
