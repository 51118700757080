import { Component, OnInit } from '@angular/core';
import { LoginRegistrationService } from '../../services/login-registration.service';
import { first, count,map, tap, debounceTime, distinctUntilChanged, switchMap, flatMap,finalize } from 'rxjs/operators';
import { Subject,Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public change_password:any;


  constructor(public loginRegisterService:LoginRegistrationService, public activeRouter:ActivatedRoute,private spinnerService: Ng4LoadingSpinnerService) {
    this.change_password = {"old_password": "", "new_password": "","re_new_password": ""};
  }

  ngOnInit() {
  }



  submitChangePasswordForm()
  {
    if(this.change_password.old_password=='')
    {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Please enter your old password'
      });
      return false;
    }

    if(this.change_password.new_password=='')
    {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Please enter your new password'
      });
      return false;
    }

    if(this.change_password.re_new_password=='')
    {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Please enter confirm password same as new password'
      });
      return false;
    }


    if (this.change_password.new_password.length < 8 || this.change_password.new_password != this.change_password.re_new_password) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Please enter password & confirm password as a same min 8 character long'
      });
      return false;
    }


    this.spinnerService.show();
    this.loginRegisterService.changePassword(this.change_password)
      .pipe(first())
      .subscribe(
        response => {
          if (response.success == true)
          {
            this.loginRegisterService.setLogin();
            this.spinnerService.hide();
            let timerInterval;
            Swal.fire({
              title: 'Your Password Change Successfully',
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
              timer: 2000,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
              onClose: () => {
                clearInterval(timerInterval);
              }
            }).then((result) => {
              if (result.value) {
                Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
              }
            })
          }
          else if (response.success == false) {
            this.spinnerService.hide();
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: response.msg
            });
          }
        },
        error => {
          console.log('Error ....');
        });


  }




}
