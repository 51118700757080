import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  protected baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }

  getCategoryData() {
    var url=this.baseURL +'get-categories';

    if(localStorage.getItem("postal_code")!=undefined  && localStorage.getItem("postal_code")!='')
    {
      url = url+'?postal_code='+localStorage.getItem("postal_code");
    }
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getCategoryBySlug(slug) {
    var url=this.baseURL +'get-category/'+slug;
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getCategoryMenuData() {
    var url=this.baseURL +'get-menu-item';

    if(localStorage.getItem("postal_code")!=undefined  && localStorage.getItem("postal_code")!='')
    {
      url = url+'?postal_code='+localStorage.getItem("postal_code");
    }

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

}
