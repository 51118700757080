import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  protected baseURL = environment.baseURL;
  locationChange:Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) {

  }

  watch():Observable<any> {
    return this.locationChange.asObservable();
  }

  locationBroadCast(data) {
    this.locationChange.next(data);
  }

  getLocation() {
    return localStorage.getItem("postal_code");
  }

  setLocation(data)
  {
    this.locationChange.next(data);
  }

  getAddressData(lat, long) {
    var url = 'https://api.opencagedata.com/geocode/v1/json?q=' + lat + '+' + long + '&key=c7f79eb19b2f4c488bf09606bd2e6589';
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getPosition():Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }



  getPinSearch(pinSearchString) {
    var url = this.baseURL+'get-address-by-pin?postal_code='+pinSearchString;

    if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
    {
      url = url+'&region='+localStorage.getItem("region");
    }

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getDeliverySlot(pinCode,date='') {
    if(date=='')
      var url = this.baseURL+'check-delivery/'+pinCode;
    else
    var url = this.baseURL+'check-delivery/'+pinCode+'?date='+date;
  
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getOrderSummery(orderCustomerAddressId) {
    var url = this.baseURL+'order-summary?order_customer_address_id='+orderCustomerAddressId;

    if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
    {
      url = url+'&region_id='+localStorage.getItem("region");
    }

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getRemoveAddress(orderCustomerAddressId) {
    var url = this.baseURL+'remove-address/'+orderCustomerAddressId;

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

}
