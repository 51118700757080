import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginRegistrationService {
  protected baseURL = environment.baseURL;
  private jwtHelper = new JwtHelperService();
  loginChange:Subject<string> = new Subject<string>();
  profilePicture:Subject<any> = new Subject<any>();
  postalCodeChange:Subject<any> = new Subject<any>();
  public loggedIn:boolean = false;
  public userID:any = null;
  public token = null;
  public userData = null;


  constructor(private http:HttpClient) {
  }

  watchProfilePicture():Observable<any> {
    return this.profilePicture.asObservable();
  }

  profilePictureBroadCast(data) {
    this.profilePicture.next(data);
  }

  watchPostalCode():Observable<any> {
    return this.postalCodeChange.asObservable();
  }

  postalCodeBroadCast(data) {
    this.postalCodeChange.next(data);
  }

  public isAuthenticated():boolean {
    const token = localStorage.getItem('token');
    if (!token || token == null || token == "" || token == "null") {
      this.loggedIn = false;
    }
    else {
      // Check whether the token is expired and return
      // true or false
      this.loggedIn = !this.jwtHelper.isTokenExpired(token);
      if (this.loggedIn) {
        var decoded = this.jwtHelper.decodeToken(token);
        if (decoded) {
          this.userID = decoded.sub;
          this.token = token;
          this.userData = decoded.details;
        }
      }
    }
    return this.loggedIn;
  }

  public getToken() {
    return this.token;
  }

  public getUserData() {
    return this.userData;
  }

  public user() {
    if (this.isAuthenticated()) {
      return this.userID;
    }
    else {
      this.logout();
    }

  }

  login(router:Router, url?:string):void {
    if (router)
      router.navigate(['/login', url]);
  }

  setLogin() {
    if (this.isAuthenticated()) {
      this.loginChange.next(this.loggedIn.toString());
    }
  }

  getLogin() {
    return this.loggedIn;
  }

  getLogout() {
    return this.http.post<any>(this.baseURL + 'logout', {'token': localStorage.getItem('token')})
      .pipe(map((res:any) => {
        return res;
      }));
  }

  watch():Observable<any> {
    return this.loginChange.asObservable();
  }

  postLogin(loginObj:Object) {
    return this.http.post<any>(this.baseURL + 'login', loginObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  postRegister(registerObj:Object) {
    return this.http.post<any>(this.baseURL + 'register', registerObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  /*postUpdate(updateObj:Object) {
   return this.http.post<any>(this.baseURL + 'api/update', {updateObj: updateObj})
   .pipe(map((res:any) => {
   return res;
   }));
   }*/

  /*getValue(id:number) {
   return this.http.get<any>(this.baseURL + 'api/update' + id)
   .pipe(map((res:any) => {
   return res;
   // login successful if there's a jwt token in the response
   }));
   }*/

  logout() {
    localStorage.setItem("token", null);
    localStorage.setItem("cartToken", 'FFBDEMOTOKEN');
    this.loggedIn = false;
    this.token = null;
    this.loginChange.next(this.loggedIn.toString());
  }


  changePassword(changePasswordObj:Object) {
    return this.http.post<any>(this.baseURL + 'change-password', changePasswordObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  profileUpdateSubmit(profileUpdateObj:Object) {
    return this.http.post<any>(this.baseURL + 'update-profile', profileUpdateObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }


  getUserAddress() {
    var url = this.baseURL + "get-user-address?token=" + localStorage.getItem("token");

    if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
    {
      url = url+'&region='+localStorage.getItem("region");
    }

    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getLoginUserDetails() {
    var url = this.baseURL + "login-user-details";
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  addAddressSubmit(addressObj:Object) {
    return this.http.post<any>(this.baseURL + 'add-address', addressObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  editAddressSubmit(addressObj:Object,id) {
    return this.http.post<any>(this.baseURL + 'update-address/'+id, addressObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }


  generateOtp(generateOtpObj:Object) {
    return this.http.post<any>(this.baseURL + 'generate-otp', generateOtpObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  otpLogin(otpLoginObj:Object) {
    return this.http.post<any>(this.baseURL + 'sign-in', otpLoginObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  ///// This area for bulk order //////

  generateBulkOtp(generateOtpObj:Object) {
    return this.http.post<any>(this.baseURL + 'generate-bulk-otp', generateOtpObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }

  bulkOrderSave(bulkOrderObj:Object) {
    return this.http.post<any>(this.baseURL + 'submit-bulk-order', bulkOrderObj)
      .pipe(map((res:any) => {
        return res;
      }));
  }
  getRestrictionInactivePincode(code) {
    var url = this.baseURL + "restriction-inactive-pincode?token=" + localStorage.getItem("token")+'&postal_code='+code;
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
  redeemPointToWalletBalace() {

    var url = this.baseURL + "point-redeem-to-wallet";

    if(localStorage.getItem("region")!=undefined  && localStorage.getItem("region")!='')
    {
      url = url+'?region_id='+localStorage.getItem("region");
    }
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
}
