import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  protected baseURL = environment.baseURL;

  constructor(private _http:HttpClient) {

  }

  getContentDetails(slug) {

    var url = this.baseURL + "cms/" + slug;

    let promise = new Promise((resolve, reject) => {
      this._http.get(url)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getFaqDetails() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/xml',
        'Authorization': 'jwt-token',
        'Access-Control-Allow-Origin': '*'
      })
    };

    var url = this.baseURL + "faq";

    let promise = new Promise((resolve, reject) => {
      this._http.get(url, httpOptions)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  postContact(contact:Object) {
    return this._http.post<any>(this.baseURL + 'contact-us', contact)
      .pipe(map((res:any) => {
        return res;
      }));
  }
}
