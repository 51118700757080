import { Component, OnInit } from '@angular/core';
import { LoginRegistrationService } from '../../services/login-registration.service';
import { first, count,map, tap, debounceTime, distinctUntilChanged, switchMap, flatMap,finalize } from 'rxjs/operators';
import { Subject,Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CartServiceService } from '../../services/cart-service.service';
import { MessageService } from '../../services/message.service';


declare var $:any;

@Component({
  selector: 'app-login-inner',
  templateUrl: './login-inner.component.html',
  styleUrls: ['./login-inner.component.scss']
})
export class LoginInnerComponent implements OnInit {

  pageStatus = 'otp';
  public login:any;
  public register:any;
  public generate_otp:any;
  public otp_login:any;

  public cartData = [];
  public totalPrice:any;
  public totalItems:any = 0;
  public shippingCost:any = 0;
  public showLoginArea: any = false;
  public showRegisterArea: any = false;
  public showOTPArea: any = true;
  timeLeft: number = 60;
  interval;
  public userObject:any;
  public settingObj:any;
  isDisabledTerms : boolean;

  constructor(
    public loginRegisterService:LoginRegistrationService,
    public activeRouter:ActivatedRoute,
    private spinnerService:Ng4LoadingSpinnerService,
    private cart:CartServiceService,
    private msgService: MessageService) {
      this.login = {"email": "", "password": "","token": localStorage.getItem("cartToken")
      };

    this.register = {
      "name": "",
      "email": "",
      "password": "",
      "confirm_password": "",
      "phone": "",
      "token": localStorage.getItem("cartToken")
    };

    this.generate_otp={phone:''};
    this.otp_login={phone:'',otp:'',token:localStorage.getItem("cartToken"),name:'',email:'',used_referral_code:''};
  }

  ngOnInit() {
    this.settingObj = JSON.parse(localStorage.getItem("settingObj"));
    this.isDisabledTerms = true;
  }

  gotoLogin() {
    this.pageStatus = 'login';
  }

  gotoRegister() {
    this.pageStatus = 'register';
  }

  gotoOTP() {
    this.pageStatus = 'otp';
  }

  gotoEnterOTP() {
    $(".otpStep1").slideUp(200);
    $(".otpStep2").slideDown(200);
  }

  gotoPhoneNum() {
    $(".otpStep1").slideDown(200);
    $(".otpStep2").slideUp(200);
  }

  submitLoginForm() {
    if (this.login.email != '') {
      if (isNaN(this.login.email)) {
        if ((!/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(this.login.email))) {
          this.msgService.errMessage('Error', 'Please enter a valid email', 'ok');
          return false;
        }
      }
      else {
        var checkEmail = /^\d{10}$/;
        if (!this.login.email.match(checkEmail)) {
          this.msgService.errMessage('Error', 'Please enter a valid phone number', 'ok');
          return false;
        }
      }
    }
    else {
      this.msgService.errMessage('Error', 'Please enter a valid email or phone number', 'ok');
      return false;
    }


    if (this.login.password == "" || this.login.password.length < 5) {
      this.msgService.errMessage('Error', 'Please enter your password', 'ok');
      return false;
    }

    this.spinnerService.show();
    this.loginRegisterService.postLogin({
        'username': this.login.email,
        'password': this.login.password,
        "token": localStorage.getItem("cartToken")
      })
      .pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            localStorage.setItem("token", response.security_token);
            localStorage.setItem("cartToken", null);
            localStorage.setItem("userName", response.user.name);
            this.loginRegisterService.setLogin();
            $(".loginModalOverlay").fadeOut();
            $(".loginModal").removeClass("lmShown");
            $("body").removeClass("noScroll");
            this.spinnerService.hide();

            this.msgService.successMessage('Success', 'You are logged in', 'ok');

            this.cart.cartAllProduct(localStorage.getItem("token"));
            this.cart.watch().subscribe((data)=> {
              this.cartData = data.cartContent;
              this.totalPrice = data.totalPrice;
              this.shippingCost = data.shippingCost;
              //this.finalPrice = data.finalPrice;
              this.totalItems = this.cartData.length;
            });


          }
          else if (response.success == false) {
            this.spinnerService.hide();
            this.msgService.errMessage('Error', 'Please enter your valid email or phone and password', 'ok');
            return false;
          }
        },
        error => {
          console.log('Error ....');
        });
  }

  submitRegisterForm() {
    if (this.register.name == "") {
      this.msgService.errMessage('Error', 'Please enter your full name', 'ok');
      return false;
    }

    if (this.register.email == "" || (!/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(this.register.email))) {

      this.msgService.errMessage('Error', 'Please enter your email', 'ok');
      return false;
    }

    if ((isNaN(this.register.phone) || this.register.phone == "" )) {
      this.msgService.errMessage('Error', 'Please enter your phone number', 'ok');

      return false;
    }

    if (this.register.phone.length < 10) {
      this.msgService.errMessage('Error', 'Please enter correct phone number', 'ok');

      return false;
    }
    if (this.register.password == '') {
      this.msgService.errMessage('Error', 'Please enter a password min 8 character long', 'ok');

      return false;
    }

    if (this.register.confirm_password == '') {
      this.msgService.errMessage('Error', 'Please enter confirm password same as password', 'ok');

      return false;
    }


    if (this.register.password.length < 8 || this.register.password != this.register.confirm_password) {
      this.msgService.errMessage('Error', 'Enter password and confirm password must be 8 characters long and same', 'ok');

      return false;
    }
    this.spinnerService.show();
    this.loginRegisterService.postRegister(this.register)
      .pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            localStorage.setItem("token", response.security_token);
            localStorage.setItem("cartToken", null);
            localStorage.setItem("userName", response.user.name);
            this.loginRegisterService.setLogin();

            $(".loginModalOverlay").fadeOut();
            $(".loginModal").removeClass("lmShown");
            $("body").removeClass("noScroll");
            this.spinnerService.hide();

            this.msgService.successMessage('Success', 'You are logged in', 'ok');

          } else if (response.success == false) {
            this.spinnerService.hide();
            this.msgService.errMessage('Error', 'Oops...', 'ok');
          }
        },
        error => {
          console.log('Error ....');
        });

  }


  submitGenerateOtpForm()
  {
    if ((isNaN(this.generate_otp.phone) || this.generate_otp.phone == "" )) {
      this.msgService.errMessage('Error', 'Please enter your phone number', 'ok');

      return false;
    }

    if (this.generate_otp.phone.length < 10) {
      this.msgService.errMessage('Error', 'Please enter correct phone number', 'ok');
      return false;
    }

    this.spinnerService.show();
    this.loginRegisterService.generateOtp(this.generate_otp).pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            this.spinnerService.hide();

            this.msgService.successMessage('OTP', 'OTP has been sent successfully. Please check your phone.', 'ok');

            $(".otpStep1").slideUp(200);
            $(".otpStep2").slideDown(200);
            this.otp_login.phone=this.generate_otp.phone;
            this.userObject = response.user;
            this.startTimer();
          }
          else if (response.success == false) {
            this.spinnerService.hide();

            this.msgService.errMessage('Error', 'Please enter your valid email or phone.', 'ok');

            return false;
          }
        },
        error => {
          console.log('Error ....');
        });
  }


  submitOtpLoginForm()
  {
    //console.log(this.userObject);
    //return false;
    if ((isNaN(this.otp_login.phone) || this.otp_login.phone == "" )) {

      this.msgService.errMessage('Error', 'Please enter your phone number', 'ok');

      return false;
    }

    if (this.otp_login.phone.length < 10) {

      this.msgService.errMessage('Error', 'Please enter correct phone number', 'ok');

      return false;
    }
    if ((isNaN(this.otp_login.otp) || this.generate_otp.otp == "" )) {

      this.msgService.errMessage('Error', 'Please enter your otp', 'ok');

      return false;
    }

    if(this.userObject.email=='')
    {
      if(this.otp_login.name=='') {

        this.msgService.errMessage('Error', 'Name is required', 'Ok');

        return false;
      }

      if(this.otp_login.email=='') {

        this.msgService.errMessage('Error', 'Email is required', 'Ok');

        return false;
      }

      if (this.otp_login.email == "" || (!/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(this.otp_login.email))) {

        this.msgService.errMessage('Error', 'Please enter your email', 'ok');
        return false;
      }

    }
    else
    {
      this.otp_login.email = this.userObject.email;
      this.otp_login.name =  this.userObject.name;
      //this.otp_login.used_referral_code = this.userObject.used_referral_code;
    }
    //console.log(this.otp_login);
    //return false;
    this.spinnerService.show();
    // '&token=' + localStorage.getItem("cartToken")
    console.log(localStorage.getItem("cartToken"));
    //return false;
    this.otp_login.token=localStorage.getItem("cartToken");

    this.loginRegisterService.otpLogin(this.otp_login).pipe(first())
      .subscribe(
        response => {
          if (response.success == true) {
            localStorage.setItem("token", response.security_token);
          //  localStorage.setItem("cartToken", response.security_token);
            localStorage.setItem("cartToken", null);
            localStorage.setItem("userName", response.user.name);
            this.loginRegisterService.setLogin();
            this.loginRegisterService.profilePictureBroadCast(response.user.image);
            $(".loginModalOverlay").fadeOut();
            $(".loginModal").removeClass("lmShown");
            $("body").removeClass("noScroll");
            this.otp_login={phone:'',otp:'',token:''};
            $(".otpStep2").slideUp(200);
            $(".otpStep1").slideDown(200);
            this.spinnerService.hide();

            this.msgService.successMessage('Success', 'You are logged in', 'ok');

            this.cart.cartAllProduct(localStorage.getItem("token"));
            this.cart.watch().subscribe((data)=> {
              this.cartData = data.cartContent;
              this.totalPrice = data.totalPrice;
              this.shippingCost = data.shippingCost;
              //this.finalPrice = data.finalPrice;
              this.totalItems = this.cartData.length;
            });
          }
          else if (response.success == false) {
            this.spinnerService.hide();

            this.msgService.errMessage('Error', response.msg, 'ok');

            return false;
          }
        },
        error => {
          console.log('Error ....');
        });
  }

  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      }
      //} else {
      //  this.timeLeft = 60;
      //}
    },1000)
  }

  //pauseTimer() {
  //  clearInterval(this.interval);
  //}
  openTerms()
  {
    window.open('https://www.freshfishbasket.in/page/terms-of-services', "_blank");
  }

}
