import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LoginRegistrationService = /** @class */ (function () {
    function LoginRegistrationService(http) {
        this.http = http;
        this.baseURL = environment.baseURL;
        this.jwtHelper = new JwtHelperService();
        this.loginChange = new Subject();
        this.profilePicture = new Subject();
        this.postalCodeChange = new Subject();
        this.loggedIn = false;
        this.userID = null;
        this.token = null;
        this.userData = null;
    }
    LoginRegistrationService.prototype.watchProfilePicture = function () {
        return this.profilePicture.asObservable();
    };
    LoginRegistrationService.prototype.profilePictureBroadCast = function (data) {
        this.profilePicture.next(data);
    };
    LoginRegistrationService.prototype.watchPostalCode = function () {
        return this.postalCodeChange.asObservable();
    };
    LoginRegistrationService.prototype.postalCodeBroadCast = function (data) {
        this.postalCodeChange.next(data);
    };
    LoginRegistrationService.prototype.isAuthenticated = function () {
        var token = localStorage.getItem('token');
        if (!token || token == null || token == "" || token == "null") {
            this.loggedIn = false;
        }
        else {
            // Check whether the token is expired and return
            // true or false
            this.loggedIn = !this.jwtHelper.isTokenExpired(token);
            if (this.loggedIn) {
                var decoded = this.jwtHelper.decodeToken(token);
                if (decoded) {
                    this.userID = decoded.sub;
                    this.token = token;
                    this.userData = decoded.details;
                }
            }
        }
        return this.loggedIn;
    };
    LoginRegistrationService.prototype.getToken = function () {
        return this.token;
    };
    LoginRegistrationService.prototype.getUserData = function () {
        return this.userData;
    };
    LoginRegistrationService.prototype.user = function () {
        if (this.isAuthenticated()) {
            return this.userID;
        }
        else {
            this.logout();
        }
    };
    LoginRegistrationService.prototype.login = function (router, url) {
        if (router)
            router.navigate(['/login', url]);
    };
    LoginRegistrationService.prototype.setLogin = function () {
        if (this.isAuthenticated()) {
            this.loginChange.next(this.loggedIn.toString());
        }
    };
    LoginRegistrationService.prototype.getLogin = function () {
        return this.loggedIn;
    };
    LoginRegistrationService.prototype.getLogout = function () {
        return this.http.post(this.baseURL + 'logout', { 'token': localStorage.getItem('token') })
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.watch = function () {
        return this.loginChange.asObservable();
    };
    LoginRegistrationService.prototype.postLogin = function (loginObj) {
        return this.http.post(this.baseURL + 'login', loginObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.postRegister = function (registerObj) {
        return this.http.post(this.baseURL + 'register', registerObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    /*postUpdate(updateObj:Object) {
     return this.http.post<any>(this.baseURL + 'api/update', {updateObj: updateObj})
     .pipe(map((res:any) => {
     return res;
     }));
     }*/
    /*getValue(id:number) {
     return this.http.get<any>(this.baseURL + 'api/update' + id)
     .pipe(map((res:any) => {
     return res;
     // login successful if there's a jwt token in the response
     }));
     }*/
    LoginRegistrationService.prototype.logout = function () {
        localStorage.setItem("token", null);
        localStorage.setItem("cartToken", 'FFBDEMOTOKEN');
        this.loggedIn = false;
        this.token = null;
        this.loginChange.next(this.loggedIn.toString());
    };
    LoginRegistrationService.prototype.changePassword = function (changePasswordObj) {
        return this.http.post(this.baseURL + 'change-password', changePasswordObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.profileUpdateSubmit = function (profileUpdateObj) {
        return this.http.post(this.baseURL + 'update-profile', profileUpdateObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.getUserAddress = function () {
        var _this = this;
        var url = this.baseURL + "get-user-address?token=" + localStorage.getItem("token");
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
            url = url + '&region=' + localStorage.getItem("region");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LoginRegistrationService.prototype.getLoginUserDetails = function () {
        var _this = this;
        var url = this.baseURL + "login-user-details";
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LoginRegistrationService.prototype.addAddressSubmit = function (addressObj) {
        return this.http.post(this.baseURL + 'add-address', addressObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.editAddressSubmit = function (addressObj, id) {
        return this.http.post(this.baseURL + 'update-address/' + id, addressObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.generateOtp = function (generateOtpObj) {
        return this.http.post(this.baseURL + 'generate-otp', generateOtpObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.otpLogin = function (otpLoginObj) {
        return this.http.post(this.baseURL + 'sign-in', otpLoginObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    ///// This area for bulk order //////
    LoginRegistrationService.prototype.generateBulkOtp = function (generateOtpObj) {
        return this.http.post(this.baseURL + 'generate-bulk-otp', generateOtpObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.bulkOrderSave = function (bulkOrderObj) {
        return this.http.post(this.baseURL + 'submit-bulk-order', bulkOrderObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    LoginRegistrationService.prototype.getRestrictionInactivePincode = function (code) {
        var _this = this;
        var url = this.baseURL + "restriction-inactive-pincode?token=" + localStorage.getItem("token") + '&postal_code=' + code;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LoginRegistrationService.prototype.redeemPointToWalletBalace = function () {
        var _this = this;
        var url = this.baseURL + "point-redeem-to-wallet";
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
            url = url + '?region_id=' + localStorage.getItem("region");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LoginRegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginRegistrationService_Factory() { return new LoginRegistrationService(i0.ɵɵinject(i1.HttpClient)); }, token: LoginRegistrationService, providedIn: "root" });
    return LoginRegistrationService;
}());
export { LoginRegistrationService };
