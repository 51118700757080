<div class="gap100"></div>
<div class="container">
  <div class="row align-items-center">
    <div class="col-md-5">
      <div class="loginHolder">
        <div class="loginTabHolder">
          <a href="javascript:;" class="active">Change Password</a>
        </div>
        <div class="loginTabContent">
          <div class="loginTabInner">
            <form (ngSubmit)="submitChangePasswordForm()" id="changePasswordForm">
              <div class="customForm">
                <img src="assets/images/icons/padlock.svg"/>
                <input type="password" placeholder="Enter your old password" id="old_password" name="old_password"
                       [(ngModel)]="change_password.old_password" required/>
              </div>
              <div class="customForm">
                <img src="assets/images/icons/padlock.svg"/>
                <input type="password" placeholder="Enter your new password" id="new_password" name="new_password"
                       [(ngModel)]="change_password.new_password" required/>
              </div>
              <div class="customForm">
                <img src="assets/images/icons/padlock.svg"/>
                <input type="password" placeholder="Retype your password" id="re_new_password" name="re_new_password"
                       [(ngModel)]="change_password.re_new_password" required/>
              </div>
              <button type="submit" class="btn btn-block btn-primary btn-lg">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 offset-md-1">
      <img src="assets/images/login-img.webp" class="loginImg"/>
    </div>
  </div>
</div>
<div class="gap100"></div>
