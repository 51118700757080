<div class="gap100"></div>
<div class="container">
    <div class="row align-items-center">
        <div class="col-md-5">
            <app-login-inner></app-login-inner>
        </div>
        <div class="col-md-5 offset-md-1">
            <img src="assets/images/login-img.webp" class="loginImg" />
        </div>
    </div>
</div>
<div class="gap100"></div>