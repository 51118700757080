import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OrderService = /** @class */ (function () {
    function OrderService(http) {
        this.http = http;
        this.baseURL = environment.baseURL;
    }
    OrderService.prototype.temporaryOrderSaveData = function (orderObj) {
        // return this.http.post<any>(this.baseURL + 'temporary-order-save', orderObj)
        //   .pipe(map((res:any) => {
        //     return res;
        //   }));
        return this.http.post(this.baseURL + 'latest-order-save', orderObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    //latest-order-save
    OrderService.prototype.orderSaveData = function (orderObj) {
        return this.http.post(this.baseURL + 'order-save', orderObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    OrderService.prototype.orderSaveCodData = function (orderObj) {
        return this.http.post(this.baseURL + 'order-save-cod', orderObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    OrderService.prototype.getOrderData = function (userToken) {
        var _this = this;
        var that = this;
        var header = {
            headers: new HttpHeaders()
                .set('Authorization', 'Basic ' + userToken)
        };
        var url = this.baseURL + "order-detail";
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url, header)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    OrderService.prototype.getOldOrderForReviewData = function (userToken) {
        var _this = this;
        var that = this;
        var header = {
            headers: new HttpHeaders()
                .set('Authorization', 'Basic ' + userToken)
        };
        var url = this.baseURL + "order-review-data";
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url, header)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    OrderService.prototype.skipOrderReview = function (userToken, orderNumber) {
        var _this = this;
        var that = this;
        var header = {
            headers: new HttpHeaders()
                .set('Authorization', 'Basic ' + userToken)
        };
        var url = this.baseURL + "order-review-skip?order_number=" + orderNumber;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url, header)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    OrderService.prototype.submitOrderReview = function (userToken, reviewObj) {
        var that = this;
        var header = {
            headers: new HttpHeaders()
                .set('Authorization', 'Basic ' + userToken)
        };
        var url = this.baseURL + "submit-order-review";
        return this.http.post(url, reviewObj)
            .pipe(map(function (res) {
            return res;
        }));
    };
    OrderService.prototype.getOrderResponse = function (orderNumber) {
        var _this = this;
        var that = this;
        var url = this.baseURL + "order-response?orderNumber=" + orderNumber;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    OrderService.prototype.cancelOrder = function (orderNumber, token) {
        var _this = this;
        var that = this;
        var header = {
            headers: new HttpHeaders()
                .set('Authorization', 'Basic ' + token)
        };
        var url = this.baseURL + "cancel-order?order_number=" + orderNumber;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url, header)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    OrderService.prototype.ajaxWalletBalance = function () {
        // return this.http.post<any>(this.baseURL + 'temporary-order-save', orderObj)
        //   .pipe(map((res:any) => {
        //     return res;
        //   }));
        return this.http.post(this.baseURL + 'ajax-wallet-total', {})
            .pipe(map(function (res) {
            return res;
        }));
    };
    OrderService.prototype.getRatingData = function () {
        var _this = this;
        var url = this.baseURL + 'get-overall-rating-feed';
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.HttpClient)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}());
export { OrderService };
