import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LocationService = /** @class */ (function () {
    function LocationService(http) {
        this.http = http;
        this.baseURL = environment.baseURL;
        this.locationChange = new Subject();
    }
    LocationService.prototype.watch = function () {
        return this.locationChange.asObservable();
    };
    LocationService.prototype.locationBroadCast = function (data) {
        this.locationChange.next(data);
    };
    LocationService.prototype.getLocation = function () {
        return localStorage.getItem("postal_code");
    };
    LocationService.prototype.setLocation = function (data) {
        this.locationChange.next(data);
    };
    LocationService.prototype.getAddressData = function (lat, long) {
        var _this = this;
        var url = 'https://api.opencagedata.com/geocode/v1/json?q=' + lat + '+' + long + '&key=c7f79eb19b2f4c488bf09606bd2e6589';
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LocationService.prototype.getPosition = function () {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (resp) {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            }, function (err) {
                reject(err);
            });
        });
    };
    LocationService.prototype.getPinSearch = function (pinSearchString) {
        var _this = this;
        var url = this.baseURL + 'get-address-by-pin?postal_code=' + pinSearchString;
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
            url = url + '&region=' + localStorage.getItem("region");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LocationService.prototype.getDeliverySlot = function (pinCode, date) {
        var _this = this;
        if (date === void 0) { date = ''; }
        if (date == '')
            var url = this.baseURL + 'check-delivery/' + pinCode;
        else
            var url = this.baseURL + 'check-delivery/' + pinCode + '?date=' + date;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LocationService.prototype.getOrderSummery = function (orderCustomerAddressId) {
        var _this = this;
        var url = this.baseURL + 'order-summary?order_customer_address_id=' + orderCustomerAddressId;
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != '') {
            url = url + '&region_id=' + localStorage.getItem("region");
        }
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LocationService.prototype.getRemoveAddress = function (orderCustomerAddressId) {
        var _this = this;
        var url = this.baseURL + 'remove-address/' + orderCustomerAddressId;
        var promise = new Promise(function (resolve, reject) {
            _this.http.get(url)
                .toPromise()
                .then(function (res) {
                resolve(res);
            }, function (msg) {
                reject(msg);
            });
        });
        return promise;
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.HttpClient)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
