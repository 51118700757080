import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginInnerComponent } from './login-inner/login-inner.component';
import { FormsModule }   from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MyDatePickerModule } from 'mydatepicker';
import { LazyLoadImageModule } from 'ng-lazyload-image';



@NgModule({
  declarations: [HeaderComponent, FooterComponent, LoginComponent, LoginInnerComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AutocompleteLibModule,
    MyDatePickerModule,
    LazyLoadImageModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ChangePasswordComponent
  ]
})
export class CoreModule { }
